import { useNavigate } from 'react-router-dom';
import "./popUpComponent.css";
import InfoIcon from '../../../../images/roundInfoIcon.svg';
import ArrowRight from '../../../../images/arrowRight.svg';

export const PopUpComponent = ({ showPopUp = false }) => {
    const navigate = useNavigate();
    return (
        <>
            {showPopUp && <div className="popup">
                <div>
                    <img src={InfoIcon} />
                </div>
                <div className="info">
                    <div>Osvojio/la si <b className="points">50 poena</b></div>
                    <div className="link" onClick={() => navigate('/profile')}> <div>Pogledaj sve poene </div> <img src={ArrowRight} /></div>
                </div>
            </div>}
        </>
    )
}
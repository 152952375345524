import { axiosInstance } from "./api_instance";


export default async function submitAnswer(quizID, questionID, answerID) {

    try {
        const response = await axiosInstance.post(`/quiz/${quizID}/answer`, {
                                                                            "questionId": questionID,
                                                                            "answerId": answerID
                                                                        })
        if (response.status === 200) {
            return response.data.success
        }

    } catch (error) {
        let msg = await error.response.data.error.message
        
        return msg
    }
} 
import React from 'react'
import MainTemplate from '../mainTemplate/mainTemplate'

const FootballCo = () => {
  return (
    <MainTemplate title="Ekskluzivan sadržaj"
                  text="Pročitaj inspirativne priče legendarnih fudbalskih asova."
                  postType="footballco"
                  path="footballco"/>
  )
}

export default FootballCo
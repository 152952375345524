const inputFields = [{ "name": "firstName", "placeHolder": "Ime", "value": "", "err": "", "isSelected": false, "isBlured": false },
{ "name": "lastName", "placeHolder": "Prezime", "value": "", "err": "", "isSelected": false, "isBlured": false },
{ "name": "email", "placeHolder": "e-mail", "value": "", "err": "", "isSelected": false, "isBlured": false },
{ "name": "password", "placeHolder": "Lozinka", "value": "", "err": "", "isSelected": false, "isBlured": false, "type": 'password' },
{ "name": "retypePassword", "placeHolder": "Ponovi lozinku", "value": "", "err": "", "isSelected": false, "isBlured": false, "type": 'password' },
{ "name": "address", "placeHolder": "Adresa", "value": "", "err": "This is err", "isSelected": false, "isBlured": false },
{ "name": "city", "placeHolder": "Grad", "value": "", "err": "", "isSelected": false, "isBlured": false },
{ "name": "phone", "placeHolder": "Broj telefona", "value": "", "err": "", "isSelected": false, "isBlured": false },
{ "name": "code", "placeHolder": "CODE", "value": "", "err": "", "isSelected": false, "isBlured": false }]

const inputFields2 = [{ "name": "email", "placeHolder": "e-mail", "value": "", "err": "", "isSelected": false, "isBlured": false },
{ "name": "password", "placeHolder": "lozinka", "value": "", "err": "", "isSelected": false, "isBlured": false, "type": "password" }]

const inputFields3 = [{ "name": "password", "placeHolder": "Unesi šifru", "value": "", "err": "", "isSelected": false, "isBlured": false, "type": "password" },
{ "name": "retypePassword", "placeHolder": "Potvrdi šifru", "value": "", "err": "", "isSelected": false, "isBlured": false, "type": "password" }]

const inputFields4 = [{ "name": "email", "placeHolder": "e-mail", "value": "", "err": "", "isSelected": false, "isBlured": false }]

export {
  inputFields,
  inputFields2,
  inputFields3,
  inputFields4
}
import React, { useState, useEffect } from 'react'
import "./quizTemplate.css"
import clockIconWhite from "../../../images/clockIconWhite.png"
import stadiumImg from "../../../images/stadiumImg.png"
import mobileHeiStar from "../../../images/mobileHeiStar.png"

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const QuizTemplate = props => {
 
    return (
        <div className="mainPage quizTemplate">
            <img className='mobileLogo' src={mobileHeiStar} />
            <img className="backgroundAdd" src={stadiumImg} />
            <div className="limit">
                <div className="title">{props.title}</div>
                <div className="imgHold">
                    <img crossOrigin='anonymous' src={`${imageUrl}` + `${props.currentQuestion.questionImage}`} />
                    <div className="clockHold">
                        <img src={clockIconWhite} />
                        {props.counter}sek
                    </div>
                </div>

                <div className="question">
                    <div>{props.currentQuestionNO}.</div>
                    <div>{props.currentQuestion.questionText}</div>
                </div>

                {props.currentQuestion.answerOptions.map(answer => (
                    <div className="answer"
                         isDisabled = {props.counter > 0 ? "false" : "true"}
                        isSelected={props.answerSelected === answer["_id"] ? "true" : "false"}
                        key={answer["_id"]}
                        onClick={props.counter > 0 ? () => props.handleAnswer(answer["_id"]): () => {}}>

                        {answer.text}
                    </div>
                ))}

                <div className="progressBar">
                    <div className="progress" style={{width: `${props.currentQuestionNO * props.progressGap}%`}}></div>
                </div>

                <div className="questionNumber">
                    <span>{props.currentQuestionNO} / {props.totalQuestions}</span>
                </div>

                <div className="submit" onClick={props.handleSubmit}>
                    {props.lastQuestion? "Završi" : "Dalje"}
                </div>


            </div>
        </div>
    )
}

export default QuizTemplate
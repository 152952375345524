import React, { useEffect, useState } from 'react';
import './rituals.css';
import ProgressBar from './progressBar/progressBar';
import mobileHeiStar from "../../../images/mobileHeiStar.png"
import desktopHeiStar from "../../../images/desktopHeiStar.png"
import { VoteBtn } from './components/voteBtn';
import { PopUpComponent } from './components/popUpComponent';
import { getRituals, voteForRitual } from '../../../api/ritualsAPI';
import { totalPoints } from '../../../signals/totalPointsSignal';

const Rituals = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [rituals, setRituals] = useState([]);
  const [voteId, setVotedId] = useState(null);

  const fetchRituals = async () => {
    const [success, error] = await getRituals();

    if (error) {
      return;
    }

    setRituals(success.data.rituals);
    setVotedId(success.data.votedRitualId);
  }

  const handleVote = async (voteId) => {
    const [success, error] = await voteForRitual(voteId);

    if (error) {
      return;
    }

    setVotedId(voteId);
    setShowPopUp(true);
    totalPoints.value += 50;
    fetchRituals();

    setTimeout(() => {
      setShowPopUp(false);
    }, 5000);
  }


  useEffect(() => {
    fetchRituals();
    return () => {
     
    }
  }, [])

  return (
    <div className="mainPage ritualsPage">
      <PopUpComponent showPopUp={showPopUp} setShowPopUp={setShowPopUp} />
      <img src={mobileHeiStar} className="floatingStar-mobile" />
      <img src={desktopHeiStar} className="floatingStar" />
      <div className="limit">
        <div className="title">Rituali</div>
        <div className="text">Prikaži nam koji ritual te čini pravim, strastvenim navijačem.</div>

        <div className="ritual-container">
          {rituals?.map((result, index) => (
            <div key={index} className={"ritual listItem " + (voteId && (result._id == voteId ? "item-voted" : "item-disabled"))}>
              <div className="ritualTextContainer">
                <div className="ritualIndex">{index + 1}. </div>
                <div className="ritualText">{result.description}</div>
              </div>
              <div className="voteContainer">
                <VoteBtn voted={result._id == voteId} handleVote={() => handleVote(result._id)} />

                <ProgressBar progress={result.percentage} voted={result._id == voteId} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rituals;
import { axiosInstance } from "./api_instance";

export default async function startNewPredictor(matchIDs) {
    try {
        const response = await axiosInstance.get("/predictor/start")
       
        return response
    } catch (error) {
        return error.response.data.error.message
    }
} 
import React, { useState } from 'react'
import "./winners.css"
import mobileHeiStar from "../../images/mobileHeiStar.png"
import desktopHeiStar from "../../images/desktopHeiStar.png"
import Ranking from './rankingList/ranking'
import PopupList from './popupList/popupList'

const WinnersPage = () => {

    const [mobileList, setMobileList] = useState(1)

    const handleMobileList = value => setMobileList(value)

    return (
        <div className="mainPage winnersPage">
            <img src={desktopHeiStar} className="floatingStar" />
            <img src={mobileHeiStar} className="floatingStar-mobile" />
            <div className="limit">
                <div className="title">Dobitnici</div>
                <div className="text">
                    Proveri ko se sve nalazi na listi dobitnika. <br />
                    Svakog 15. u mesecu objavljujemo finalnu rang listu za prethodnih 30 dana.
                </div>

                <div className="ranking-holder onlyDesktop">
                    <Ranking />
                    <PopupList />
                </div>


                <div className="ranking-holder onlyMobile">
                    { <div className="ranking-btn"
                        onClick={() => setMobileList(1)}
                         isActive={mobileList === 1 ? "true" : false}>Rang Lista</div>}
                    <div className="ranking-btn"
                        onClick={() => setMobileList(2)}
                        isActive={mobileList === 2 ? "true" : false}>Rang lista za bonus kvizove</div>

                    {mobileList === 1 && <Ranking />}
                    {mobileList === 2 && <PopupList />}
                </div>

            </div>
        </div>
    )
}

export default WinnersPage
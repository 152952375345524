import React from 'react'
import {Link} from 'react-router-dom'
import "./card.css"
import chevronRightRed from "../../../images/chevronRightRed.svg"


const imageUrl = process.env.REACT_APP_IMAGE_URL;

const Card = props => {
 
  return (
    <div className="cardComponent">
        <img crossOrigin='anonymous' src={`${imageUrl}` + `${props.thumbnail}`}/>
        <div className="cardInfo">
            <div>{props.title}</div>
            <div>{props.shortDescription}</div>
            <Link to={`/dashboard/fans/${[props.path]}/${props.id}`}>
            Ceo tekst
                <img src={chevronRightRed}/>
            </Link>
        </div>
    </div>
  )
}

export default Card
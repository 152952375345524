import { axiosInstance } from "./api_instance";

export default async function submitPopupQuiz(ID, submitData) {

    try {
        const response = await axiosInstance.post(`/quiz/${ID}/submit/popup`, {"qa": submitData})
        if(response.status === 200){
            return response.data.success
        }
        
    } catch (error) {
        let msg = await error.response.data.error.message
       
        return msg
    }
} 
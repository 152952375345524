import { useState, useEffect } from 'react';
import "./predictorCard.css";
import mobileHeiStar from "../../../../images/predictorHeiStar.svg";
import Accordion from "../predictorAccordion/predictorAccordion";
import moment from "moment";
import { submitPrediction, updatePrediction } from '../../../../api/predictorAPI';
import { toast } from 'react-toastify';
import submitNewPredictor from '../../../../api/submitNewPredictor';
import fetchUserInfo from '../../../../api/fetchUserInfo'
import { totalPoints } from '../../../../signals/totalPointsSignal';

const imageUrl = process.env.REACT_APP_CONTENT_URL;

const PredictorCard = ({ match, gameWeek, counter, submitCounter, handleSubmitCounter }) => {
    
    const formatMatchData = (data) => {
        return {
            matchId: data.matchId,
            bothTeamScores: data.bothTeamScores,
            totalGoals: data.totalGoals != null ? Number(data.totalGoals) : data.totalGoals,
            totalFouls: data.totalFouls != null ? Number(data.totalFouls) : data.totalFouls,
            moreCorners: data.moreCorners,
            totalCorners: data.totalCorners != null ? Number(data.totalCorners) : data.totalCorners,
            ballPossesion: data.ballPossesion,
            finalScore: data.finalScore != null ? data.finalScore : [null, null],
            moreShoots: data.moreShoots,
            predictionId: data.predictionId
        }
    }

    const [matchData, setMatchData] = useState(match);
    const [copyMatchData, setCopyMatchData] = useState(match);
    const [isPlayed, setIsPlayed] = useState(false)

    const displayButtons = () => {
        if (moment().isAfter(moment(gameWeek.canUpdateUpToTime))) {
            return false;
        } else {
            return true;
        }
    }

    const fetchUserPoints = async () => {
        let response = await fetchUserInfo()
        if (response.data) {
            totalPoints.value = response.data.points
        }
    }

    


    const submit = async () => {
        const data = formatMatchData(matchData);
       
        let submitMatchData = {
            "matchId": data.matchId,
            "finalScore": data.finalScore.join(''),
            "ballPossesion": data.ballPossesion,
            "totalCorners": data.totalCorners,
            "totalFouls": data.totalFouls,
            "moreShoots": data.moreShoots,
        }
       
       

        let response = await submitNewPredictor(submitMatchData)
       
        if (response?.data?.success) {
            toast.success(response.data.success.message)
            // Here when submitCounter is 2 it will clear timer because both predictions are submited
            let newSubmitCounter = submitCounter +1
            handleSubmitCounter(newSubmitCounter)
        }
        else {
            toast.error(response)
        }
       
        setIsPlayed(true)
        await fetchUserPoints()
    }

    useEffect(() => {
        if(counter > 0){

        }
        else{
            if(isPlayed){

            }
            else{
                submit()
            }
            
        }
    }, [counter])
    

    return (
        <div className="predictor-card">
            {isPlayed && <div className="isPlayedCard"></div>}
            <div className="predictor-card-header">
                <div>{match.matchDescription}</div>
            </div>
            <div className="predictor-card-body">
                <img src={mobileHeiStar} className="floating-star" />
                <div className="match-box">
                    <div className="team-box">
                        <div>
                            <img crossOrigin='anonymous' style={{ width: "50px", height: "50px" }} src={`${imageUrl}` + `${match.homeTeam.logo}`} />
                        </div>
                        <div>{match.homeTeam.name}</div>
                    </div>
                    <div className="score-box">
                        -
                    </div>

                    <div className="team-box">
                        <div>
                            <img crossOrigin='anonymous' style={{ width: "50px", height: "50px" }} src={`${imageUrl}` + `${match.awayTeam.logo}`} />
                        </div>
                        <div>{match.awayTeam.name}</div>
                    </div>
                </div>
                <div>
                    <Accordion match={matchData} setMatchData={setMatchData} />
                </div>

                {!isPlayed &&
                    <div className="button-wrapper">
                        <button className="predict-button-submit" onClick={(e) => submit()}>Potvrdi</button>
                    </div>}


            </div>
        </div>
    )
}

export default PredictorCard;
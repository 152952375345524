import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import './header.css'
import headerLogo from "../../images/headerLogo.png"
import exitSVG from "../../images/exitSVG.svg"
import { useAuth } from '../../hooks/useAuth'
import DropDown from './dropdown';
import { dropdownArray } from './dropdownArray';
import burgerMenuIcon from '../../images/burgerMenuIcon.png'
import closeIcon from '../../images/closeIcon.png'
import fetchUserInfo from '../../api/fetchUserInfo';
import { totalPoints } from '../../signals/totalPointsSignal';
import { effect } from "@preact/signals-react";
import chevronDownWhite from "../../images/chevronDownWhite.svg";
import chevronDownGreen from "../../images/chevronDownGreen.svg";

const Header = () => {
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
    };

    const [isOpen, setIsOpen] = useState([false, false, false])
    const [isMobileOpen, setIsMobileOpen] = useState(false)

    const handleMobile = () => {
        let temp = isMobileOpen
        setIsMobileOpen(!temp)
    }

    const openDropdown = id => {
        let temp = [false, false, false]
        temp[id] = true
        setIsOpen(temp)
    }

    const closeDropdown = () => {
        let temp = [false, false, false]
        setIsOpen(temp)
    }

    const mobileCloseDropdown = () =>{
        closeDropdown()
        handleMobile()
    }

    const switchDropdown = (e, id) => {
        let temp = isOpen
        let currentOpen = temp.indexOf(true)
        if(currentOpen === id){
            closeDropdown()
        }
        else{
            openDropdown(id)
        }

    }
  

    useEffect(() => {
        const fetch = async () => {
            let response = await fetchUserInfo()
            if (response.data) {
                totalPoints.value = response.data.points
            }
            
        }

        fetch()
    }, [1])

    effect(async () => {
        // loads something like `http://${address.value}/` and fully handles errors so the async function will never throw
    })



    return (
        <nav className="header-our">
            <img src={headerLogo} />

            <div className="container-our">
                <div className="list-our">
                    <NavLink to="/dashboard/start">POČETNA </NavLink>

                    {dropdownArray.map(link => (
                        <NavLink to={link.path}
                            key={link.id}
                            onMouseEnter={() => openDropdown(link.id)}
                            onMouseLeave={closeDropdown}  >
                            {link.title}
                            {isOpen[link.id] && <DropDown list={link.elements} closeDropdown={closeDropdown} />}
                        </NavLink>
                    ))}

                    <NavLink to="/dashboard/rules">Pravilnik</NavLink>
                </div>

                <div className="logout-list">
                    <NavLink to="/dashboard/profile" isProfile="true">
                        Moj Profil
                        <div className="points">{totalPoints}pt.</div>
                    </NavLink>
                    <div className="line"></div>
                    <div className="btn"
                        onClick={handleLogout}>
                        <img src={exitSVG} />
                        Odjavi se
                    </div>
                </div>
            </div>




            {isMobileOpen &&
                <div className="list-our mobileHeader-our">
                    <NavLink to="/dashboard/start"
                             onClick={handleMobile}>POČETNA </NavLink>

                    {dropdownArray.map(link => (
                        <>
                            <div className="mobileHeader-expander">
                                <NavLink to={link.path}
                                         onClick={handleMobile}
                                    className='arrow'
                                    key={link.id}>
                                    {link.title}
                                </NavLink>

                                <img src={isOpen[link.id] ? chevronDownGreen : chevronDownWhite} 
                                     className="expandIcon"
                                     onClick={(e) => switchDropdown(e, link.id)}/>
                            </div>

                            {isOpen[link.id] && <DropDown list={link.elements} closeDropdown={mobileCloseDropdown} />}
                        </>

                    ))}

                    <NavLink to="/dashboard/rules"
                             onClick={handleMobile}>PRAVILNIK</NavLink>

                    <div className="line"></div>

                    <div className="btn"
                        onClick={handleLogout}>
                        <img src={exitSVG} />
                        Odjavi se
                    </div>
                </div>}

            <div className="logout-list mobileHeader-our">
                <img src={headerLogo} />

                <NavLink to="/dashboard/profile" >
                    MOJ PROFIL
                    <div className="points">{totalPoints}pt.</div>
                </NavLink>

                <img src={isMobileOpen ? closeIcon : burgerMenuIcon}
                    className="burger"
                    onClick={handleMobile} />
            </div>


        </nav>
    )
}

export default Header
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Header from "../components/header/header";
import './mobileSwitch.css'

export const ProtectedRoute = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/signup" />;
  }

  return (
    <div className="flex-order"> {/**  This will be our header**/ }
      <Header/>
      
      <Outlet className="example" />
    </div>
  )
};
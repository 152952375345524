import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './quizPage.css'
import chevronRightGrey from '../../../images/chevronRightGrey.svg'
import notifyIconWhite from "../../../images/notifyIconWhite.png"
import mobileHeiStar from "../../../images/mobileHeiStar.png"
import desktopHeiStar from "../../../images/desktopHeiStar.png"
import grass from "../../../images/grass.png"
import fetchAllQuiz from '../../../api/fetchAllQuiz'
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

const QuizPage = () => {

    const [alwaysOnArray, setAlwaysOnArray] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentTime, setCurrentTime] = useState(moment().tz("Europe/Belgrade"))

    useEffect(() => {
        const fetch = async () => {
            let response = await fetchAllQuiz()
            if (response.data) {
                if(response.data.alwaysOnQuizzes.length === 0){
                    toast.warning("Trenutno nema kvizova za ovaj mesec.")
                }
                else{
                    setAlwaysOnArray(response.data.alwaysOnQuizzes)
                }           
            }
            else {
                toast.warning("Trenutno ne postoje rezultati pretrage u bazi podataka.")
            }
         
            setLoading(false)
        }

        fetch()

    }, [1])

    return (
        <div className="mainPage gamesPage quizPage">
            <img src={desktopHeiStar} className="floatingStar" />
            <img src={mobileHeiStar} className="floatingStar-mobile" />
            <img src={grass} className="floating-grass" />

            <div className="limit">
                <div className="title">Mesečni kviz</div>
                <div className="text">Odgovori na fudbalska pitanja i osvoji poene. <br />
                    Svaki kviz možeš odigrati samo jednom. Na svako pitanje moraš odgovoriti za 15 sekundi.</div>


                <div className="boxBig-holder">
                    {alwaysOnArray.map(quiz => (
                        <div className="boxBig" key={quiz["_id"]}>
                            {moment.duration(moment.tz(quiz.startDate, "Europe/Belgrade").diff(currentTime)) > 0 &&
                                <div className="lightGray50"></div>}

                            {moment.duration(moment.tz(quiz.endDate, "Europe/Belgrade").diff(currentTime)) < 0 &&
                                <div className="lightGray50"></div>}

                            {quiz.userCompletedQuiz && <div className="lightGray50"></div>}


                            <div className="points"> {quiz.points}points</div>
                            <img src={notifyIconWhite} />
                            <div className="title">{quiz.title}</div>
                            <div className="text">{quiz.description}</div>
                            <Link className="btn"
                                isPlayed={quiz.userCompletedQuiz ? "true" : "false"}
                                to={`/dashboard/games/quiz/always-on/${quiz["_id"]}`}>
                                {quiz.userCompletedQuiz ? "Kviz je odigran" : "Započni kviz"}
                                {!quiz.userCompletedQuiz && <img src={chevronRightGrey} />}
                            </Link>
                        </div>
                    ))}
                </div>







            </div>
        </div>
    )
}

export default QuizPage
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./myProfile.css"
import stadiumImg from "../../images/stadiumImg.png"
import infoIcon from "../../images/infoIcon.svg"
import fetchUserInfo from '../../api/fetchUserInfo'
import { totalPoints } from '../../signals/totalPointsSignal'
import fetchUserRank from '../../api/fetchUserRank'
import chevronRightWhite from "../../images/chevronRightWhite.svg"
import closeIcon from "../../images/closeIcon.png"
import loadingGIF from "../../images/loadingGIF.gif"

const MyProfile = () => {

    const [profileInfo, setProfileInfo] = useState({})
    const [isCopy, setIsCopy] = useState(false)
    const [myRank, setMyRank] = useState([])
    const [infoMessage, setInfoMessage] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingRank, setLoadingRank] = useState(true)

    const handleInfoMessage = () => setInfoMessage(!infoMessage)

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);

            setIsCopy(true)
            setTimeout(() => {
                setIsCopy(false)
            }, "3000")

        } catch (err) {

        }
    }

    useEffect(() => {
        const fetch = async () => {
            let response = await fetchUserInfo()
            if (response.data) {

                setProfileInfo(response.data)
                setLoading(false)
            }
            let response2 = await fetchUserRank()
            if (response2.data) {

                setMyRank(response2.data)
                setLoadingRank(false)
            }

        }

        fetch()
    }, [])


    return (
        <div className="mainPage myProfile">
            {!loading &&
                <>
                    <img className="backgroundAdd" src={stadiumImg} />
                    <div className="limit">
                        <div className="flex">
                            <div className="pointsCircle">
                                <div>{totalPoints}</div>
                                <div>poena</div>
                                {!infoMessage &&
                                    <div className="infoAbsolute" onClick={handleInfoMessage}>
                                        <img src={infoIcon} />
                                    </div>}

                                {infoMessage &&
                                    <div className="rectangle-absolute">
                                        <img src={infoIcon} />
                                        <div>
                                            <div className="infoText">Saznaj kako sve možeš da osvojiš poene. </div>
                                            <Link to="/dashboard/rules">
                                                Idi na Pravilnik
                                                <img src={chevronRightWhite} />
                                            </Link>
                                        </div>

                                        <img src={closeIcon} className="closeI" onClick={handleInfoMessage} />
                                    </div>}

                            </div>
                            <div>
                                <div className="title">Ćao {profileInfo.firstName}</div>
                                <div className="text">Hvala što učestvuješ u Heineken® Klubu Šampiona </div>
                            </div>

                        </div>

                        <div className="subTitle">
                            Tvoj trenutni rang
                        </div>

                        {!loadingRank && myRank.map((item, i) => (
                            <div className={i === 2 ? "infoHold greenList lastGreenList" : "infoHold greenList"}
                                key={item["_id"]}
                                isMe={item.isMe ? "true" : "false"}>
                                <div>{item.rank}. mesto</div>
                                <div>{item.phone}</div>
                                <div>{item.points} poena</div>
                            </div>
                        ))}

                        {loadingRank && <img src={loadingGIF} className="loadingRank"/>}

                        <div className="subTitle">
                            Podeli svoj kod sa prijateljima
                        </div>

                        <div className="infoHold">
                            {profileInfo.referralCode}
                            {!isCopy && <svg onClick={() => copyToClipboard(profileInfo.referralCode)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M11.1993 11.9999C11.9461 11.9999 12.3195 11.9999 12.6047 11.8546C12.8556 11.7268 13.0595 11.5228 13.1874 11.2719C13.3327 10.9867 13.3327 10.6133 13.3327 9.86659V3.46659C13.3327 2.71985 13.3327 2.34648 13.1874 2.06126C13.0595 1.81038 12.8556 1.60641 12.6047 1.47858C12.3195 1.33325 11.9461 1.33325 11.1993 1.33325H7.46602C6.71928 1.33325 6.34591 1.33325 6.06069 1.47858C5.80981 1.60641 5.60584 1.81038 5.47801 2.06126C5.33268 2.34648 5.33268 2.71985 5.33268 3.46659M4.79935 14.6666H8.53268C9.27942 14.6666 9.65279 14.6666 9.938 14.5213C10.1889 14.3934 10.3929 14.1895 10.5207 13.9386C10.666 13.6534 10.666 13.28 10.666 12.5333V6.13325C10.666 5.38652 10.666 5.01315 10.5207 4.72793C10.3929 4.47705 10.1889 4.27307 9.938 4.14524C9.65279 3.99992 9.27942 3.99992 8.53268 3.99992H4.79935C4.05261 3.99992 3.67924 3.99992 3.39403 4.14524C3.14315 4.27307 2.93917 4.47705 2.81134 4.72793C2.66602 5.01315 2.66602 5.38652 2.66602 6.13325V12.5333C2.66602 13.28 2.66602 13.6534 2.81134 13.9386C2.93917 14.1895 3.14315 14.3934 3.39403 14.5213C3.67924 14.6666 4.05261 14.6666 4.79935 14.6666Z" stroke="white" stroke-width="1.5" />
                            </svg>}
                            {isCopy && <span>Kopirano!</span>}
                        </div>

                        <div className="codeDescription">
                            Podeli svoj kod sa tri prijatelja i osvoji dodatne poene.
                        </div>

                        <div className="line"></div>

                        <div className="subTitle">
                            Lične informacije
                        </div>

                        <div className="infoHold">{profileInfo.firstName + " " + profileInfo.lastName}</div>
                        <div className="infoHold">{profileInfo.email}</div>
                        {profileInfo.address && <div className="infoHold">{profileInfo.address}</div>}
                        <div className="infoHold">{profileInfo.city}</div>
                        <div className="infoHold" style={{ marginBottom: "55px" }}>{profileInfo.phone}</div>
                    </div>
                </>}

        </div>
    )
}

export default MyProfile
import React from 'react'
import "./articleCard.css"
import Card from '../../mainTemplate/card'
import paginateLeft from "../../../../images/paginateLeft.svg"
import paginateRight from "../../../../images/paginateRight.svg"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  point1: {
    breakpoint: { max: 1920, min: 1150 },
    items: 4,
  },
  point2: {
    breakpoint: { max: 1150, min: 950 },
    items: 3,
    partialVisibilityGutter: 40
  },
  point3:{
    breakpoint: { max: 950, min: 820 },
    items: 3,
  },
  point4:{
    breakpoint: { max: 820, min: 750 },
    items: 2,
    partialVisibilityGutter: 60
  },
  point5:{
    breakpoint: { max: 750, min: 620 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 620, min: 370 },
    items: 1,
    partialVisibilityGutter: 60
  },

  xs: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    partialVisibilityGutter: 20
  }
}

const ArticleCard = props => {

  

  return (
    <div className="articleCard">
      <div className="title">{props.articleTitle}</div>
      <div className="text">{props.articleText}</div>



      <Carousel responsive={responsive} partialVisbile>
        {props.articles.map(item => (
          <Card key={item["_id"]}
            id={item["_id"]}
            title={item.title}
            shortDescription={item.shortDescription}
            contentImage={item.contentImage}
            path={item.postType}
            thumbnail={item.thumbnail} />
        ))}
      </Carousel>
    </div>
  )
}

export default ArticleCard
import React, { useState } from 'react'
import './auth.css'
import mobileHeiStar from "../../images/mobileHeiStar.png"
import desktopHeiStar from "../../images/desktopHeiStar.png"
import grass from "../../images/grass.png"
import SigninPage from './signin/signin'
import SignupPage from './signup/signup'
import SendReset from "./resetPass/sendReset.jsx"


const AuthPage = () => {

  const [loadReset, setLoadReset] = useState(false)
  const switchReset = () => {
    let temp = loadReset
    setLoadReset(!temp)
  }

  return (
    <div className="authPage">
      <img src={desktopHeiStar} className="floatingStar" />
      <img src={mobileHeiStar} className="floatingStar-mobile" />
      <img src={grass} className="floating-grass" />
      
      <SignupPage />
      <div className="line"></div>

      {loadReset ? <SendReset switchReset={switchReset} />
        : <SigninPage switchReset={switchReset} />}
    </div>
  )
}

export default AuthPage
import React from 'react'
import "./itemTemplate.css"
import stadiumImg from "../../../images/stadiumImg.png"
import starBall from "../../../images/starBall.png"
import ArticleCard from './articleCard/articleCard'
import fetchPosts from '../../../api/fetchPosts.js'
import { toast } from 'react-toastify';
import quoteIcon from "../../../images/quoteIcon.png"
import ImageGallery from "react-image-gallery";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const imageArray = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ]

const ItemTemplate = props => {
    return (
        <div className="mainPage itemTemplate">
            <img src={starBall} className="floating-ball" />
            <div className="limit">
                <div className="title">{props.title}</div>
                <div className="text">{ props.text?.toUpperCase() }</div>
                <img crossOrigin='anonymous' src={`${imageUrl}` + `${props.contentImage}`} className="mainImage" />

                {props.path === "podcast" && <img className="podcastQuoute" src={quoteIcon} />}

                <div className="mainDescription"
                     dangerouslySetInnerHTML={{__html: props.description}}>
                </div>

                {props.contentVideo &&
                    <video crossOrigin='anonymous' src={`${imageUrl}` + `${props.contentVideo}`} width="600" height="300" controls="controls" />
                }

                {props.path === "footballco" && <img className="podcastQuoute" src={quoteIcon} />}

                {props.description2 &&
                    <div className="mainDescription"
                         dangerouslySetInnerHTML={{__html: props.description2}}>
                    </div>

                }

                {props.path === "footballco" && <ImageGallery crossOrigin="anonymous"
                                                              showFullscreenButton={false}
                                                              showPlayButton={false} 
                                                              items={props?.contentGallery ? props?.contentGallery?.map(e => {return {original: imageUrl + e, thumbnail: imageUrl + e}}) : imageArray} />}


                <ArticleCard articles={props.otherPosts}
                             articleTitle={props.articleTitle}
                             articleText={props.articleText}
                              />

            </div>
        </div>
    )
}

export default ItemTemplate
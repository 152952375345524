import { axiosInstance } from "./api_instance";

export default async function signupAPI(data) {
    try {
        const response = await axiosInstance.post("/auth/register", { ...data })
        
        if(response.status === 201){
            return response
        }
        else{
            return response
        }
    } catch (error) {
        return(error)
    }
} 
import React, { useState, useEffect } from 'react'
import "./popupList.css"
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { namesArray } from '../rankingList/namesArray';
import fetchPopupRank from '../../../api/fetchPopupRank';

const PopupList = () => {

    const [popupList, setPopupList] = useState([])

    useEffect(() => {
        const fetch = async () => {
            let response = await fetchPopupRank()
           
            if (response?.data) {
              
                setPopupList(response.data.success.data)
            }
        }
                                
        fetch()
    }, [1])

   
    return (
        <div className="popupList">
            <div className="paginate-tab">
                <div className="subTitle">Rang lista za bonus kvizove</div>
            </div>

            <Accordion>
                {popupList?.map(({ quizTitle, rankTable }, i) => {
                    return (
                        <AccordionItem header={quizTitle} key={i}>
                            {rankTable?.slice(0, 10).map(({ phone, place }, i) => {
                                return (
                                    <div className="popup-winner" key={phone}>
                                        <div key={i}>{place}.</div>
                                        <div key={i}>{phone}</div>
                                    </div>
                                )
                            })}
                        </AccordionItem>
                    )
                })}

                {popupList?.length === 0 &&

                    <AccordionItem header="Bonus Kviz 1">
                        <div className="popup-winner">
                            <div></div>
                            <div>Još nema rezultata za ovaj kviz</div>        
                        </div>
                    </AccordionItem>
                }
            </Accordion>
        </div>
    )
}

export default PopupList
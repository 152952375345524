import React, { useEffect, useState } from 'react'
import grass from "../../../images/grass.png"
import starBall from "../../../images/starBall.png"
import { toast } from 'react-toastify';
import loadingGIF from "../../../images/loadingGIF.gif"
import PredictorCard from './predictorCard/predictorCard';
import './predictor.css';
import PredictorSlider from './predictorSlider/predictorSlider';
import { getGameWeek, getGameWeeks } from '../../../api/predictorAPI';
import clockIconWhite from "../../../images/clockIconWhite.png"
import startNewPredictor from '../../../api/startNewPredictor';

const Predictor = () => {
    const [loading, setLoading] = useState(true);
    const [gamesArray, setGamesArray] = useState([])
    const [counter, setCounter] = useState(1000)
    const [submitCounter, setSubmitCounter] = useState(0)

    const handleCounter = (value) => setCounter(value)
    const handleSubmitCounter = (value) => setSubmitCounter(value)

    let timeout;

    useEffect(() => {

        const start = async () => {
            let response = await startNewPredictor()
          
            if (response?.data?.success?.data) {
                toast.success(response.data.success.data.message)
                setCounter(100)
                setGamesArray(response.data.success.data.currentWeekMatches)
            }
            else if(response?.data?.error){
                setGamesArray([])
                toast.error(response.data.error.message)
            }
            else {
                toast.error(response)
            }
           setLoading(false)
        }

        start()
        
    }, [1])

    useEffect(() => {

        if (counter === 1000) {
            /*This will do nothing, its just init value of counter that will skip this if statement */
        }
        else if (counter > 0) {
            timeout = setTimeout(() => handleCounter(counter - 1), 1000)
            if(submitCounter === 2){ // This means that both predictions are submited and timer should be cleared
                clearTimeout(timeout)
            }
        }
        else {
            toast.error("Vreme je isteklo.")
            setGamesArray([])
        }
        return () => clearTimeout(timeout)

    }, [counter])


    


    const submitRemasterPredictor = (data) => {
       
    }

    
    return (
        <div className="mainPage predictorPage">
            <img src={starBall} className="floating-ball" />
            <img src={grass} className="floating-grass" />
            <div className="limit">
                <div className="title">UCL rezultati</div>
                <div className="text">Priseti se ishoda i statistike UEFA Champions League utakmica iz sezone 2023/24. Pokaži svoje znanje i osvoji bodove.</div>

                {loading && <div className="loading-spinner-wrapper"><img src={loadingGIF} className="loading-screen" /></div>}

                <div className="cardHolder">

                    { counter > 0 && counter < 101 && submitCounter !== 2 && 
                        <div className="clockHold">
                            <img src={clockIconWhite} />
                            {counter}sek
                        </div>
                    }


                    {gamesArray.map((match, i) => (
                        <PredictorCard key={i} match={match} counter={counter}
                                       submitCounter={submitCounter} 
                                       handleSubmitCounter={handleSubmitCounter} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Predictor
import './App.css';
import { useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import { Route, Routes, Navigate, useLocation, ScrollRestoration, useNavigate } from 'react-router-dom';
import { ProtectedRoute } from "./routes/protectedRoute";
import { PublicRoute } from './routes/publicRoute';
import WelcomePage from './components/welcomePage/welcomePage';
import MainPage from './components/mainPage/mainPage';
import AuthPage from './components/authPage/auth';
import FansPage from './components/fansPage/fans';
import LandingFromLink from './components/authPage/landingFromLink/landingFromLink';
import ConfirmProfile from './components/authPage/confirmProfile/confirmProfile';
import GamesPage from './components/gamesPage/games';
import QuizPage from './components/gamesPage/quizPage/quizPage';
import AlwaysOnQuiz from './components/gamesPage/alwaysOnQuiz/alwaysOnQuiz';
import MyProfile from './components/myProfilePage/myProfile';
import PrizeWinners from './components/prizeWinners/prizeWinners';
import PrizePage from './components/prizePage/prizePage';
import WinnersPage from './components/winnersPage/winners';
import Influencers from './components/fansPage/influencers/influencers';
import FootballCo from "./components/fansPage/footballco/footballco";
import Podcast from "./components/fansPage/podcast/podcast";
import ItemInfluencer from './components/fansPage/influencers/itemInfluencer/itemInfluencer';
import ItemPodcast from './components/fansPage/podcast/itemPodcast/itemPodcast';
import ItemFootbalCo from './components/fansPage/footballco/itemFootballCo/itemFootballCo';
import Rituals from './components/fansPage/ritualsPage/rituals';
import Predictor from './components/gamesPage/predictor/predictor';
import PopupQuiz from './components/gamesPage/popupQuiz/popupQuiz';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import heartBeatAPI from './api/heartbeat';
import { useAuth } from './hooks/useAuth'
import relogUser from './api/relogUser';
import Rules from './components/rulesPage/rulesPage';

function App() {

  const { logout, login } = useAuth();
  const location = useLocation()
  const navigate = useNavigate();
  const refreshToken = window.localStorage.getItem("refreshToken")

  const handleLogout = async () => {
    await logout();
  };
 
  const handleRelog = async () => {
    await handleLogout()
    let response = await relogUser()
    
    // After response if success here we call useAuth hook to store token
    if (response?.status === 200) {
      login(response.data.success.data.token.token)
    }
    else {
      handleLogout()
      toast.error("Sesija je istekla, molimo vas ulogujte se ponovo")
    }
  }

  useEffect(() => {

    const checkToken = async () => {
      let response = await heartBeatAPI()
      

      if (response?.data) {
        let validToken = response.data.heartStillBeats
        if (!validToken && location.pathname.includes("/dashboard")) {
          if (refreshToken) {
            // Here we should call handleRelog function
            await handleRelog()
          }
          else {
            handleLogout()
            toast.error("Sesija je istekla, molimo vas ulogujte se ponovo")
          }
        }
      }
      else {
        handleLogout()
        toast.error("Sesija je istekla, molimo vas ulogujte se ponovo")
      }
    }

    if (location.pathname !== "/signup" && location.pathname !== "/") {
      checkToken()
    }
  }, [1])

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const activationToken = urlParams.get('activationToken');
  const resetToken = urlParams.get('resetToken');

  useEffect(() => {
    if (activationToken) {
      navigate(`/activate-user?activationToken=${activationToken}`, { replace: true })
    }
  }, [1])

  useEffect(() => {
    if (resetToken) {
      navigate(`/reset-password?activationToken=${resetToken}`, { replace: true })
    }
  }, [1])


  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/signup" element={<AuthPage />} />
          <Route path="/reset-password" element={<LandingFromLink />} />
          <Route path="/activate-user/" element={<ConfirmProfile />} />
        </Route>

        <Route path="/dashboard" element={<ProtectedRoute />}>
          <Route path="start" element={<MainPage />} />
          <Route path="/dashboard/games" element={<GamesPage />} />
          <Route path="/dashboard/games/predictor" element={<Predictor />} />
          <Route path="/dashboard/games/quiz" element={<QuizPage />} />
          <Route path="/dashboard/games/quiz/always-on/:id" element={<AlwaysOnQuiz />} />
          <Route path="/dashboard/games/quiz/popup/:id" element={<PopupQuiz />} />
          <Route path="/dashboard/fans" element={<FansPage />} />
          <Route path="/dashboard/fans/footballco" element={<FootballCo />} />
          <Route path="/dashboard/fans/footballco/:id" element={<ItemFootbalCo />} />
          <Route path="/dashboard/fans/influencers" element={<Influencers />} />
          <Route path="/dashboard/fans/influencers/:id" element={<ItemInfluencer />} />
          {/*<Route path="/dashboard/fans/podcast" element={<Podcast />} />
          <Route path="/dashboard/fans/podcast/:id" element={<ItemPodcast />} />*/}
          <Route path="/dashboard/fans/rituals" element={<Rituals />} />
          <Route path="/dashboard/prize-winners" element={<PrizeWinners />} />
          <Route path="/dashboard/prize-winners/prizes" element={<PrizePage />} />
          <Route path="/dashboard/prize-winners/winners" element={<WinnersPage />} />
          <Route path="/dashboard/rules" element={<Rules />} />
          <Route path="/dashboard/profile" element={<MyProfile />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />

      </Routes>

      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} />
      <ScrollToTop />
    </div >
  );
}

export default App;

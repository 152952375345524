import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import QuizTemplate from '../quizTemplate/quizTemplate'
import startPopupQuiz from '../../../api/startPopupQuiz';
import { toast } from 'react-toastify';
import submitPopupQuiz from '../../../api/submitPopupQuiz';
import fetchUserInfo from '../../../api/fetchUserInfo';
import { totalPoints } from '../../../signals/totalPointsSignal';

const PopupQuiz = () => {

    let { id } = useParams()
    const navigate = useNavigate()
    let timeout;

    const [counter, setCounter] = useState(15)
    const [loading, setLoading] = useState(true)
    const [lastQuestion, setLastQuestion] = useState(false)
    const [answerSelected, setAnswerSelected] = useState("")
    const [currentQuestion, setCurrentQuestion] = useState({})
    const [currentQuestionNO, setCurrentQuestionNO] = useState(0)
    const [allQuestions, setAllQuestions] = useState([])
    const [submitData, setSubmitData] = useState([])
    const [title, setTitle] = useState("")

    const handleCounter = (value) => setCounter(value)
    const handleAnswer = (value) => setAnswerSelected(value)

    const handleSubmit = async () => {

        let data = [...submitData]
        data.push({
            questionId: currentQuestion["_id"],
            answerId: answerSelected
        })
        await setSubmitData(data)

        if (lastQuestion === true) {
           
            await finishQuiz(data)
        }
        else {

            let newQuestion = allQuestions[currentQuestionNO]
            let newQuestionNo = currentQuestionNO + 1

            setCurrentQuestion(newQuestion)
            setCurrentQuestionNO(newQuestionNo)
            if (newQuestionNo === 10) {
                setLastQuestion(true)
            }
        }
        handleAnswer("")
        setCounter(15)
    }

    const finishQuiz = async (dataArray) => {
        let response = await submitPopupQuiz(id, dataArray)

        if (response.message) {
            navigate("/dashboard/games", { replace: true })
            toast.success(response.message + ", imali ste " + response.data.correctAnswers + " od " + response.data.quizQuestionsLength + " tačnih odgovora.")
            setSubmitData([])
            setCurrentQuestion({})
            setCurrentQuestionNO(0)
            // If everything is okay then we will fetch points for that user
            let pointsResponse = await fetchUserInfo()
            if (pointsResponse.data) {
                totalPoints.value = pointsResponse.data.points
            }
        }
        else {
            toast.error(response)
        }
    }

    useEffect(() => {
        const start = async () => {
            let response = await startPopupQuiz(id)
            if (response.data) {
                setAllQuestions(response.data.quiz.questions)
                setTitle(response.data.quiz.title)
                setCurrentQuestionNO(1)
                setCurrentQuestion(response.data.quiz.questions[0])
                
            }
            else if (response) {
                toast.error(response)
                navigate("/dashboard/games", { replace: true })
            }
            else {
                toast.error("Doslo je do greske, molimo vas pokusajte kasnije.")
                navigate("/dashboard/games", { replace: true })
                
            }
            setLoading(false)
        }
        start()
    }, [1])



    useEffect(() => {
        if (counter > 0) {
            timeout = setTimeout(() => handleCounter(counter - 1), 1000)
        }
        else {
            toast.error("Vreme je isteklo.")
        }

        return () => clearTimeout(timeout)

    }, [counter])

    return (
        <div className="popupQuiz">
            {!loading && <QuizTemplate currentQuestion={currentQuestion}
                currentQuestionNO={currentQuestionNO}
                totalQuestions={10}
                handleSubmit={handleSubmit}
                handleAnswer={handleAnswer}
                answerSelected={answerSelected}
                counter={counter}
                handleCounter={handleCounter}
                lastQuestion={lastQuestion}
                title={title}
                progressGap={10} />}
        </div>
    )
}

export default PopupQuiz


/* */
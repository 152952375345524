import React, { useEffect, useState } from 'react'
import "./alwaysOnQuiz.css"
import QuizTemplate from '../quizTemplate/quizTemplate'
import startQuiz from '../../../api/startQuiz'
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import submitAnswer from '../../../api/submitAnswer';
import fetchUserInfo from '../../../api/fetchUserInfo';
import { totalPoints } from '../../../signals/totalPointsSignal';

const AlwaysOnQuiz = () => {

  let { id } = useParams()
  const navigate = useNavigate()

  const [currentQuestion, setCurrentQuestion] = useState({})
  const [currentQuestionNO, setCurrentQuestionNO] = useState(0)
  const [answerSelected, setAnswerSelected] = useState("")
  const [lastQuestion, setLastQuestion] = useState(false)
  const [counter, setCounter] = useState(15)
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState("")

  let timeout;


  useEffect(() => {
    const start = async () => {
      let response = await startQuiz(id)
      if (response.data) {
        setCurrentQuestion(response.data.quiz.questions[0])
        setTitle(response.data.quiz.title)
        setCurrentQuestionNO(response.data.questionNo)
       
      }
      else if (response) {
        toast.error(response)
        navigate("/dashboard/games/quiz", { replace: true })
      }
      else {
        toast.error("Došlo je do greške, molimo vas pokušajte kasnije.")
        navigate("/dashboard/games/quiz", { replace: true })
       
      }
    
      setLoading(false)
    }

    start()
  }, [1])

 

  useEffect(() => {
    let counterZeroSubmit = async () => {
      await handleSubmit()
    }

    if (counter > 0) {
       timeout = setTimeout(() => handleCounter(counter - 1), 1000)
    }
    else {
        counterZeroSubmit()
        toast.error("Vreme je isteklo.")
    }

    return () => clearTimeout(timeout)
    
}, [counter])



  const handleSubmit = async () => {
    let response = await submitAnswer(id, currentQuestion["_id"], answerSelected)
   
    setCounter(15)
    setAnswerSelected("")
    
    // Here we will just submit answer to the last question and redirect user to quiz page
    if (lastQuestion === true) {
      navigate("/dashboard/games/quiz", { replace: true })
      toast.success(response.message + ", imali ste " + response.data.correctAnswers + " od " + response.data.quizQuestionsLength + " tačnih odgovora.")
    }
    // Here is the next question flow
    else {
      if (response.data) {

        if (response.data.hasNext === true) {
          setCurrentQuestionNO(response.data.questionNo)
          setCurrentQuestion(response.data.nextQuestion)
        }
        else {
          setCurrentQuestionNO(response.data.questionNo)
          setCurrentQuestion(response.data.nextQuestion)
          setLastQuestion(true)
        }
      }
    }
   
    let pointsResponse = await fetchUserInfo()
    if(pointsResponse.data){
      totalPoints.value = pointsResponse.data.points
    }

  }

  const handleAnswer = (value) => setAnswerSelected(value)
  const handleCounter = (value) => setCounter(value)


  return (
    <div className="alwaysOn">
      {!loading && <QuizTemplate currentQuestion={currentQuestion}
        currentQuestionNO={currentQuestionNO}
        totalQuestions={15}
        handleSubmit={handleSubmit}
        answerSelected={answerSelected}
        handleAnswer={handleAnswer}
        counter={counter}
        handleCounter={handleCounter}
        lastQuestion={lastQuestion}
        title={title}
        progressGap={6.66} />}
    </div>
  )
}

export default AlwaysOnQuiz
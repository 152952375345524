import { axiosInstance } from "./api_instance";


export default async function fetchPosts(postType) {

    try {
        const response = await axiosInstance.get(`/posts?postType=${postType}`)
        if(response.status === 200){
            return response.data.success
        }

    } catch (error) {
        let msg = await error.response.data.error.message
       
        return msg
    }
} 

import { axiosInstance } from "./api_instance";

export default async function submitNewPredictor(data) {
    try {
        const response = await axiosInstance.post("/predictor/submit", {...data})
       
        return response
    } catch (error) {
        return error.response.data.error.message
    }
} 
import React from 'react'
import { Link } from 'react-router-dom'
import './fans.css'
import chevronRightGrey from '../../images/chevronRightGrey.svg'
import mobileHeiStar from "../../images/mobileHeiStar.png"
import desktopHeiStar from "../../images/desktopHeiStar.png"
import grass from "../../images/grass.png"


const FansPage = () => {
    return (
        <div className="mainPage fansPage">
            <img src={desktopHeiStar} className="floatingStar" />
            <img src={mobileHeiStar} className="floatingStar-mobile" />
            <img src={grass} className="floating-grass" />
            <div className="limit">
                <div className="title">Strastveni navijači</div>
                <div className="text">Uživaj u jedinstvenim fudbalskim pričama i ritualima.</div>

                <div className="boxBig-holder">
                    <div className="boxBig">
                        <div className="points-our">Osvoji poene</div>
                        <div className="title">RITUALI</div>
                        <div className="text">Pokaži nam koji ritual te čini pravim, strastvenim navijačem.</div>
                        <Link className="btn"
                            to="/dashboard/fans/rituals">
                            Glasaj za ritual
                            <img src={chevronRightGrey} />
                        </Link>
                    </div>

                    <div className="boxBig">
                        <div className="title">Ekskluzivan sadržaj</div>
                        <div className="text">Pročitaj inspirativne priče legendarnih fudbalskih asova.</div>
                        <Link className="btn"
                            to="/dashboard/fans/footballco">
                            Saznaj više
                            <img src={chevronRightGrey} />
                        </Link>
                    </div>

                    <div className="boxBig">
                        <div className="title">Priče navijača</div>
                        <div className="text">Pročitaj priče navijača iz celog sveta, među kojima su i domaće, poznate ličnosti.</div>
                        <Link className="btn"
                            to="/dashboard/fans/influencers">
                            Saznaj više
                            <img src={chevronRightGrey} />
                        </Link>
                    </div>

                    {/*<div className="boxBig">
                        <div className="title">PODKAST</div>
                        <div className="text">Scelerisque nisl pulvinar auctor mauris pretium suspendisse phasellus pellentesque.</div>
                        <Link className="btn"
                            to="/dashboard/fans/podcast">
                            Read more
                            <img src={chevronRightGrey} />
                        </Link>
                      </div>*/}

                </div>
            </div>
        </div>
    )
}

export default FansPage
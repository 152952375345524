import React from 'react'
import { Link } from 'react-router-dom'
import './dropdown.css'

const DropDown = props => {
    return (
        <div className="dropdown">
            {props.list.map(item => (
                <Link to={item.path}
                    onClick={() => props.closeDropdown()}>
                    {item.title}
                </Link>
            ))}

        </div>
    )
}

export default DropDown
import * as yup from "yup";

const SignupSchema = yup.object().shape({
  firstName: yup.string().matches(/^[a-zA-ZšŠđĐžŽčČćĆабвгдђежзијклљмнњопрстћуфхцчџшАБВГДЂЕЖЗИЈКЛЉМНЊОПРСТЋУФХЦЧЏШ ]+$/, "Ime ne sme sadržati cifre i znakove")
    .required("Ovo polje je obavezno").trim(),
  lastName: yup.string().matches(/^[a-zA-ZšŠđĐžŽčČćĆабвгдђежзијклљмнњопрстћуфхцчџшАБВГДЂЕЖЗИЈКЛЉМНЊОПРСТЋУФХЦЧЏШ ]+$/, "Prezime ne sme sadržati cifre i znakove")
    .required("Ovo polje je obavezno").trim(),
  email: yup.string().matches(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i, "E-mail adresa mora biti u ispravnom formatu")
    .required("Ovo polje je obavezno").trim(),
  password: yup.string().required("Ovo polje je obavezno"),
  retypePassword: yup.string().oneOf([yup.ref('password'), null], "Ovo polje se mora poklapiti sa šifrom")
    .required("Ovo polje je obavezno").trim(),
  city: yup.string().required("Ovo polje je obavezno").trim(),
  phone: yup.string().matches(/\d{8,9}$/, "Ovo polje mora sadržati 8 ili 9 cifara")
    .min(8, "Broj telefona mora biti validan")
    .max(9, "Broj telefona mora biti validan")
    .required("Ovo polje je obavezno"),
});

export { SignupSchema }
import React,{useState} from 'react'
import './inputHolder.css'
import infoIcon from "../../../images/infoIcon.svg"

const InputHolder = props => {

    const [showInfo, setShowInfo] = useState(false)

    const handleInfo = () => setShowInfo(!showInfo)

    return (
        <div className='inputHolder'>
            {props.fields.name === "phone" && <span class="prefix">+381</span>}
            <input name={props.fields.name}
                type={props.fields.type ? props.fields.type : 'text'}
                placeholder={props.fields.placeHolder}
                {...props.register(props.fields.name)}
                has-prefix={props.fields.name=== "phone" ? "true" : false}
                maxlength={props.fields.name==="phone"? "9" : "199" }
                style={{textTransform: props.fields.name === "code" ? "uppercase" : "none" }}/>


            {props.fields.name === "code" && <img className="infoIcon" src={infoIcon} onClick={handleInfo}/>}
            {props.fields.name === "code" && showInfo &&  <div className="info-text">Ukoliko si dobio/la kod od prijatelja, ovde ga možeš uneti.</div>}

            {props.err[props.fields.name] && <span className='err'>{props.err[props.fields.name].message}</span>}
        </div>
    )
}

export default InputHolder
import React from 'react'
import "./prizePage.css"
import chevronRightWhite from '../../images/chevronRightWhite.svg'
import infoIcon from "../../images/infoIcon.svg"
import mobileHeiStar from "../../images/mobileHeiStar.png"
import desktopHeiStar from "../../images/desktopHeiStar.png"
import PrizeBox from './prizeBox'
import { prizeArray1, prizeArray2, prizeArray3 } from './prizeArray'


const PrizePage = () => {
    return (
        <div className="mainPage prizePage">
            <img src={desktopHeiStar} className="floatingStar" />
            <img src={mobileHeiStar} className="floatingStar-mobile" />
            <div className="limit">
                <div className="title">Mesečne nagrade Heineken® Kluba Šampiona.</div>
                <div className="text">Sakupljaj poene, jer svakog meseca možeš da osvojiš neku od sjajnih nagrada.</div>

                {/*<div className="perks">
                    <div className="infoIcon">
                        <img src={infoIcon} />
                    </div>

                    <div className="info">
                        <div>Perks</div>
                        <div>avg, sept -{">"} perks (5% maxi online popust, 10% balon u fudbalski, 20% horeca)</div>
                        <a href="">
                            Saznaj vise
                            <img src={chevronRightWhite} />
                        </a>
                    </div>
                 </div>*/}

                <div className="subTitle">Glavna nagrada </div>

                {prizeArray1.map(prize => (
                    <PrizeBox ley={prize.name} name={prize.name} place={prize.place} image={prize.image} isMain="true" />
                ))}

                <div className="line"></div>


                <div className="subTitle">Ostale nagrade </div>

                <div className="prizeHolder">
                    {prizeArray2.map(prize => (
                        <PrizeBox ley={prize.name} name={prize.name} place={prize.place} image={prize.image} />
                    ))}

                </div>

                <div className="line"></div>


                <div className="subTitle">Bonus Kviz </div>

                <div className="prizeHolder">
                    {prizeArray3.map(prize => (
                        <PrizeBox ley={prize.name} name={prize.name} place={prize.place} image={prize.image} />
                    ))}

                </div>


            </div>
        </div>
    )
}

export default PrizePage
import React from 'react'
import "../auth.css"
import ResetPass from '../resetPass/resetPass'
import './landingFromLink.css'

const LandingFromLink = () => {
    return (
        <div className="authPage landingLink">
                <ResetPass/>
        </div>  
    )
}

export default LandingFromLink
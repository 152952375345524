import { axiosInstance } from "./api_instance";


export default async function fetchRankList(month) {

    try {
        const response = await axiosInstance.get(`/ranks?month=${month}`)
        if(response.status === 200){
            return response
        }

    } catch (error) {
        let msg = await error.response.data.error.message
       
        return msg
    }
} 
import { axiosInstance } from "./api_instance";

export default async function loginAPI(data) {
    try {
        const response = await axiosInstance.post("/auth/login", { ...data })
       
        return response
    } catch (error) {
        return error
    }
} 
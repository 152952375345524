import arrowRight from "../../../../images/arrow-right.svg";
import checkLoading from "../../../../images/check-loading.svg";
import "./voteBtn.css";

export const VoteBtn = ({ voted = false, handleVote }) => {
  return (
    <>
      {voted && (
          <div  onClick={handleVote} className="voteButton-voted">
            <div><img src={checkLoading} /></div>
          </div>
      )}
      {!voted && (
        <div onClick={handleVote} className="voteButton">
          <div className="vote-text">Glasaj</div>
          <div><img src={arrowRight} /></div>
        </div>
      )}
    </>
  );
};

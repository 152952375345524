import { axiosInstance } from "./api_instance";


export default async function fetchPopupRank() {

    try {
        const response = await axiosInstance.get(`/ranks/pop-up`)
        if(response.status === 200){
            return response
        }

    } catch (error) {
        let msg = await error.response.data.error.message
        
        return msg
    }
} 
import React from 'react'
import { Link } from 'react-router-dom'
import "./prizeWinners.css"
import chevronRightGrey from '../../images/chevronRightGrey.svg'
import mobileHeiStar from "../../images/mobileHeiStar.png"
import desktopHeiStar from "../../images/desktopHeiStar.png"
import grass from "../../images/grass.png"

const PrizeWinners = () => {
    return (
        <div className="mainPage gamesPage prizeWinners">
            <img src={desktopHeiStar} className="floatingStar" />
            <img src={mobileHeiStar} className="floatingStar-mobile" />
            <img src={grass} className="floating-grass" />
            <div className="limit">
                <div className="info">
                    <div>
                        <div className="title">Nagrade i dobitnici</div>
                        <div className="text">Za najstrastvenije navijače pripremili smo sjajne nagrade. </div>
                    </div>
                </div>

                <div className="boxBig-holder">
                    <div className="boxBig">
                        <div className="title">NAGRADE</div>
                        <div className="text">Karte za UCL meč ligaške faze 24/25 po tvom izboru, i druge nagrade.</div>
                        <Link className="btn"
                            to="/dashboard/prize-winners/prizes">
                            Saznaj više
                            <img src={chevronRightGrey} />
                        </Link>
                    </div>

                    <div className="boxBig">
                        <div className="title">DOBITNICI</div>
                        <div className="text">Proveri ko se sve nalazi na listi dobitnika. </div>
                        <Link className="btn"
                            to="/dashboard/prize-winners/winners">
                            Saznaj više
                            <img src={chevronRightGrey} />
                        </Link>
                    </div>

                </div>

            </div>

        </div >
    )
}

export default PrizeWinners
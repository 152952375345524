import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
import './confirmProfile.css'
import "../auth.css"
import activateUserAPI from '../../../api/activateUser'

const ConfirmProfile = props => {

    const [message, setMessage] = useState("")
    const [searchParams] = useSearchParams();
    const activationToken = searchParams.get("activationToken");
    const navigate = useNavigate();


    useEffect(() => {

        async function handleActivateUser() {
            const response = await activateUserAPI(activationToken)
            setMessage(response)
            const url = new URL(window.location.href);
            const newUrl = `${url.origin}${url.pathname}${url.hash}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
            setTimeout(() => {
                navigate("/signup", { replace: true })
            }, "3000");
        }

        handleActivateUser()
    }, [])


    return (
        <div className="authPage confirmProfile">
            <div>
                <div className="title">{message} </div>
            </div>
        </div>
    )
}

export default ConfirmProfile
import { axiosInstance } from "./api_instance";

export async function getRituals() {
    try {
        const response = await axiosInstance.get("/rituals")
        return [response.data.success, null]
    } catch (error) {
        return [null, error]
    }
}

export async function voteForRitual(id) {
    try {
        const response = await axiosInstance.patch(`/rituals/${id}`)
        return [response, null]
    } catch (error) {
        return [null, error]
    }
}
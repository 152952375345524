import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import ItemTemplate from '../../itemTemplate/itemTemplate'
import fetchPostByID from '../../../../api/fetchPostByID'
import { toast } from 'react-toastify';

const ItemInfluencer = () => {

    const [postInfo, setPostInfo] = useState({})
    const [otherPosts, setOtherPosts] = useState([])
    let { id } = useParams()

    useEffect(() => {
        const fetch = async () => {
            let response = await fetchPostByID(id)
           
            if (response.data) {
                setPostInfo(response.data.post)
                setOtherPosts(response.data.posts)
            }
            else {
                toast.error(response)
            }
           
        }

        fetch()

    }, [id])

   

    return (
        <div className="itemInfluencer">
            <ItemTemplate title={postInfo.title}
                text={postInfo.subTitle}
                contentImage={postInfo.contentImage}
                description={postInfo.description}
                contentGalery={postInfo.contentGalery}
                contentVideo={postInfo.contentVideo}
                otherPosts={otherPosts}
                path="influencers"
                articleTitle="Ostale priče"
                articleText="Pogledaj uz koje rituale druge poznate ličnosti prate fudbal." />
        </div>
    )
}

export default ItemInfluencer
import React from 'react'
import chevronRightWhite from '../../../images/chevronRightWhite.svg'
import chevronRightGrey from '../../../images/chevronRightGrey.svg'
import { inputFields4 } from '../inputJSON';
import InputHolder from '../inputHolder/inputHolder'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./resetPass.css";
import forgotPasswordAPI from '../../../api/forgotPassword';
import { toast } from 'react-toastify';

const sendResetSchema = yup.object().shape({
    email: yup.string().matches(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i, "E-mail adresa mora biti u ispravnom formatu")
        .required("Ovo polje je obavezno"),
});

const SendReset = props => {

    const onSubmit = async (data) => {
       
        let response = await forgotPasswordAPI(data.email)
        toast.success(response)
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(sendResetSchema)
    })



    return (
        <div className="signinPage resetPass">
            <div className="title">Unesite svoju e-mail adresu: </div>
            <div className="text">Da biste resetovali lozinku, unesite svoju e-mail adresu koju ste koristili sa Heineken nalogom.</div>

            {inputFields4.map(input => (
                <InputHolder key={input.name} fields={input} err={errors} register={register} />
            ))}

            <div className="btns">
                <div className="btn back"
                    onClick={props.switchReset}>
                    NAZAD
                    <img src={chevronRightGrey} />
                </div>

                <div className="btn"
                    onClick={handleSubmit(onSubmit)}>
                    POŠALJI
                    <img src={chevronRightWhite} />
                </div>
            </div>



        </div>
    )
}

export default SendReset
import React, { useState } from 'react'
import "./signin.css";
import { useAuth } from '../../../hooks/useAuth';
import InputHolder from '../inputHolder/inputHolder'
import chevronRightWhite from '../../../images/chevronRightWhite.svg'
import { inputFields2 } from '../inputJSON';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import loginAPI from '../../../api/loginAPI'
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { toast } from 'react-toastify';

const SigninSchema = yup.object().shape({
    email: yup.string().matches(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i, "E-mail adresa mora biti u ispravnom formatu")
        .required("Ovo polje je obavezno"),
    password: yup.string().required("Ovo polje je obavezno"),
});

const SigninPage = props => {

    const { login } = useAuth();
    const [rememberMe, setRememberMe] = useLocalStorage("rememberMe", false)
    const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", null)

    const handleRememberMe = () => setRememberMe(!rememberMe)

    
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(SigninSchema)
    })

    const onSubmit = async (data) => {
        data["rememberMe"] = rememberMe
      
        let response = await loginAPI(data)
        // After response if success here we call useAuth hook to store token
        if (response.status) {
            if (response.status === 200) {
                login(response.data.success.data.auth.token)
                if (response.data.success.data.auth.refreshToken) {
                    setRefreshToken(response.data.success.data.auth.refreshToken)
                }
            }
        }
        else {
            toast.error(response.response.data.error.message)
        }

    }

    return (
        <div className="signinPage">
            <div className="title">Uloguj se</div>
            <div className="text">Unesi iste podatke kao i prilikom registracije.</div>

            {inputFields2.map(input => (
                <InputHolder key={input.name} fields={input} err={errors} register={register} type={input.type} />
            ))}

            <div className="holder">
                <div className="radioHolder">
                    <input type="checkbox" checked={rememberMe} onChange={handleRememberMe} />
                    <label>Zapamti me</label>
                </div>
            </div>

            <div className="btn"
                onClick={handleSubmit(onSubmit)}>
                Uloguj se
                <img src={chevronRightWhite} />
            </div>

            <div className="forgot"
                onClick={props.switchReset}>
                Zaboravljena lozinka?
                <img src={chevronRightWhite} />
            </div>
        </div>
    )
}

export default SigninPage
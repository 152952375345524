import React, { useState, useEffect } from 'react'
import "./ranking.css"
import paginateLeft from "../../../images/paginateLeft.svg"
import paginateRight from "../../../images/paginateRight.svg"

import fetchRankList from '../../../api/fetchRankList'

const monthsArray = [{ name: "MAJ", value: "may" }, { name: "JUN", value: "june" },
{ name: "JUL", value: "july" }, { name: "AVG", value: "august" }, { name: "SEP", value: "september" }, { name: "OKT", value: "october" }]

const Ranking = () => {

  const [months, setMonths] = useState(monthsArray.slice(0, 3))
  const [rankArray, setRankArray] = useState([])
  const [currentMonth, setCurrentMonth] = useState("may")
  const [loadMore, setLoadMore] = useState(0)

  const handleNextMonth = () => {
    let index = monthsArray.findIndex(object => object.value === currentMonth)

    if (index < (monthsArray.length - 1)) {
      let newIndex = index + 1
      if (newIndex === monthsArray.length - 1) {
        setMonths(monthsArray.slice(3, 6))
      }
      else {
        setMonths([monthsArray[newIndex - 1], monthsArray[newIndex], monthsArray[newIndex + 1]])
      }
      setCurrentMonth(monthsArray[newIndex].value)
    }
  }

  const handlePrevMonth = () => {
    let index = monthsArray.findIndex(object => object.value === currentMonth)
    if (index > 0) {
      let newIndex = index - 1
      if (newIndex === 0) {
        setMonths(monthsArray.slice(0, 3))
      }
      else {
        setMonths([monthsArray[newIndex - 1], monthsArray[newIndex], monthsArray[newIndex + 1]])
      }
      setCurrentMonth(monthsArray[newIndex].value)
    }
  }

  const handleMonth = e => {
    let newIndex = monthsArray.findIndex(object => object.value === e.target.attributes.value.nodeValue)
    if (newIndex === monthsArray.length - 1) {
      setMonths(monthsArray.slice(3, 6))
    }
    else if (newIndex === 0) {
      setMonths(monthsArray.slice(0, 3))
    }
    else {
      setMonths([monthsArray[newIndex - 1], monthsArray[newIndex], monthsArray[newIndex + 1]])
    }

    setCurrentMonth(monthsArray[newIndex].value)

  }


  const handleLoadMore = () => {
    let temp = loadMore
    if (temp < 2) {
      temp++
    }
    setLoadMore(temp)
  }

  useEffect(() => {
    const fetch = async () => {
      let response = await fetchRankList(currentMonth)
      if (response?.data?.success) {

        setRankArray(response.data.success.data)
      }
    }

    fetch()

  }, [currentMonth])

  return (
    <div className="rankingList">
      <div className="paginate-tab">
        <div className="subTitle">Rang lista</div>
        <div className="rank-pagination">
          <img src={paginateLeft} onClick={handlePrevMonth} />

          {months.map(month => (
            <div className={month.value === currentMonth ? "month isCurrent" : "month"}
              key={month.name}
              value={month.value}
              onClick={(e) => handleMonth(e)}>{month.name}</div>
          ))}

          <img src={paginateRight} onClick={handleNextMonth} />
        </div>
      </div>

      {rankArray.length !== 0 && <div className="prize-name">2 karte za UCL, za grupnu fazu sezone 24/25.</div>}
      {rankArray.slice(0, 1).map((winner, i) => (
        <div className="winner-info" key={winner.id}>
          <div className="place">{i + 1}.</div>
          <div className="name">{winner.phone}</div>
          <div className="point">{winner.points}pt.</div>
        </div>
      ))
      }

      {rankArray.length !== 0 && <div className="prize-name">Fudbalska lopta</div>}
      {rankArray.slice(1, 5).map((winner, i) => (
        <div className="winner-info" key={winner.id}>
          <div className="place">{i + 2}.</div>
          <div className="name">{winner.phone}</div>
          <div className="point">{winner.points}pt.</div>
        </div>
      ))
      }

      {rankArray.length !== 0 && <div className="prize-name">UCL jakna</div>}
      {rankArray.slice(5, 10).map((winner, i) => (
        <div className="winner-info" key={winner.id}>
          <div className="place">{i + 6}.</div>
          <div className="name">{winner.phone}</div>
          <div className="point">{winner.points}pt.</div>
        </div>
      ))
      }

      {rankArray.length !== 0 && <div className="prize-name">Ranac</div>}
      {rankArray.slice(10, 15).map((winner, i) => (
        <div className="winner-info" key={winner.id}>
          <div className="place">{i + 10}.</div>
          <div className="name">{winner.phone}</div>
          <div className="point">{winner.points}pt.</div>
        </div>
      ))
      }

      {rankArray.length !== 0 && <div className="prize-name">Vaučer za termin u balonu</div>}
      {rankArray.slice(15, 20).map((winner, i) => (
        <div className="winner-info" key={winner.id}>
          <div className="place">{i + 16}.</div>
          <div className="name">{winner.phone}</div>
          <div className="point">{winner.points}pt.</div>
        </div>
      ))
      }

      {rankArray.length !== 0 && <div className="prize-name">Vaučer za „Stejšn klub“</div>}
      {rankArray.slice(20, 25).map((winner, i) => (
        <div className="winner-info" key={winner.id}>
          <div className="place">{i + 21}.</div>
          <div className="name">{winner.phone}</div>
          <div className="point">{winner.points}pt.</div>
        </div>
      ))
      }

      {rankArray.length !== 0 && <div className="prize-name">Polo majica</div>}
      {rankArray.slice(25, 30).map((winner, i) => (
        <div className="winner-info" key={winner.id}>
          <div className="place">{i + 26}.</div>
          <div className="name">{winner.phone}</div>
          <div className="point">{winner.points}pt.</div>
        </div>
      ))
      }

      {rankArray.length !== 0 && <div className="prize-name">Majica</div>}
      {rankArray.slice(30, 35).map((winner, i) => (
        <div className="winner-info" key={winner.id}>
          <div className="place">{i + 31}.</div>
          <div className="name">{winner.phone}</div>
          <div className="point">{winner.points}pt.</div>
        </div>
      ))
      }

      {(loadMore !== 0) && rankArray.length !== 0 && <>
        {rankArray.length !== 0 && <div className="prize-name">Flašica</div>}
        {rankArray.slice(35, 55).map((winner, i) => (
          <div className="winner-info" key={winner.id}>
            <div className="place">{i + 36}.</div>
            <div className="name">{winner.phone}</div>
            <div className="point">{winner.points}pt.</div>
          </div>
        ))
        }

        {rankArray.length !== 0 && <div className="prize-name">Kapa</div>}
        {rankArray.slice(55, 65).map((winner, i) => (
          <div className="winner-info" key={winner.id}>
            <div className="place">{i + 56}.</div>
            <div className="name">{winner.phone}</div>
            <div className="point">{winner.points}pt.</div>
          </div>
        ))
        }
      </>}

      {(loadMore === 2) && <>
        {rankArray.length !== 0 && <div className="prize-name">Heineken Pivo</div>}
        {rankArray.slice(65, 100).map((winner, i) => (
          <div className="winner-info" key={winner.id}>
            <div className="place">{i + 66}.</div>
            <div className="name">{winner.phone}</div>
            <div className="point">{winner.points}pt.</div>
          </div>
        ))
        }
      </>}


      {
        (loadMore !== 2) && rankArray.length !== 0 && <div className="loadMore"
          onClick={handleLoadMore}>
          PRIKAŽI JOŠ
        </div>
      }


    </div >
  )
}

export default Ranking
import { axiosInstance } from "./api_instance";

export default async function resetPasswordAPI(data) {
    try {
        const response = await axiosInstance.post("/auth/reset-password", { ...data })
      
        return response.data.success.message
    } catch (error) {
        let msg = await error.response.data.error.message
       
        return msg
    }
} 
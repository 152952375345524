import React from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import "./resetPass.css"
import chevronRightWhite from '../../../images/chevronRightWhite.svg'
import chevronRightGrey from '../../../images/chevronRightGrey.svg'
import { inputFields3 } from '../inputJSON';
import InputHolder from '../inputHolder/inputHolder'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import resetPasswordAPI from '../../../api/resetPassword'
import { toast } from 'react-toastify';


const ResetSchema = yup.object().shape({
    password: yup.string().required("Ovo polje je obavezno"),
    retypePassword: yup.string().oneOf([yup.ref('password'), null], "Ovo polje se mora poklapiti sa šifrom")
        .required("Ovo polje je obavezno"),
});

const ResetPass = props => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const activationToken = searchParams.get("activationToken")

    const handleClick = () => {
        navigate("/signup", { replace: true })
    }

    const onSubmit = async (data) => {
        data["activationToken"] = activationToken
        let response = await resetPasswordAPI(data)
        toast.success(response);
        const url = new URL(window.location.href);
        const newUrl = `${url.origin}${url.pathname}${url.hash}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        setTimeout(() => {
            navigate("/signup", { replace: true })
        }, "3000");
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(ResetSchema)
    })

    return (
        <div className="signinPage resetPass">
            <div className="title">Kreiraj novu šifru: </div>
            <div className="text">Unesite novu jedinstvenu šifru za vaš nalog.</div>

            {inputFields3.map(input => (
                <InputHolder key={input.name} fields={input} err={errors} register={register} />
            ))}

            <div className="btns">
                <div className="btn back"
                    onClick={handleClick}>
                    PONIŠTI
                    <img src={chevronRightGrey} />
                </div>

                <div className="btn"
                    onClick={handleSubmit(onSubmit)}>
                    POŠALJI
                    <img src={chevronRightWhite} />
                </div>
            </div>



        </div>
    )
}

export default ResetPass
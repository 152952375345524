import React, { useEffect, useState } from 'react'
import './mainTemplate.css'
import grass from "../../../images/grass.png"
import starBall from "../../../images/starBall.png"
import Card from "./card.jsx"
import fetchPosts from '../../../api/fetchPosts.js'
import { toast } from 'react-toastify';
import loadingGIF from "../../../images/loadingGIF.gif"

const MainTemplate = props => {

    const [itemArray, setItemArray] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetch = async () => {
            let response = await fetchPosts(props.postType)
            if (response.data) {
                setItemArray(response.data)
                if (response.data.length === 0) {
                    toast.warning("Trenutno ne postoje rezultati pretrage u bazi podataka.")
                }

            }
            else {
                toast.error(response)
            }

        }

        fetch()
        setLoading(false)

    }, [1])

   

    return (
        <div className="mainPage mainTemplate">
            <img src={starBall} className="floating-ball" />
            <img src={grass} className="floating-grass" />
            <div className="limit">
                <div className="title">{props.title}</div>
                <div className="text">{props.text}</div>

                {loading && <img src={loadingGIF} className="loading-screen" />}

                {!loading &&
                    <div className="cardHolder">
                        {itemArray.map(item => (
                            <Card key={item["_id"]}
                                id={item["_id"]}
                                title={item.title}
                                subTitle={item.subTitle}
                                shortDescription={item.shortDescription}
                                postType={item.postType}
                                description={item.description}
                                contentImage={item.contentImage}
                                contentVideo={item.contentVideo}
                                contentGallery={item.contentGallery}
                                path={props.path}
                                thumbnail={item.thumbnail} />
                        ))}

                    </div>}
            </div>
        </div>
    )
}

export default MainTemplate
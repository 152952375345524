import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./games.css"
import mobileHeiStar from "../../images/mobileHeiStar.png"
import desktopHeiStar from "../../images/desktopHeiStar.png"
import grass from "../../images/grass.png"
import Notification from './notification'
import chevronRightGrey from '../../images/chevronRightGrey.svg'
import notifyIconWhite from "../../images/notifyIconWhite.png"
import fetchAllQuiz from '../../api/fetchAllQuiz'
import { toast } from 'react-toastify';

const GamesPage = () => {

    const [popup, setPopup] = useState({})
    const [loading, setLoading] = useState(true)
   
    useEffect(() => {
        const fetch = async () => {
            let response = await fetchAllQuiz()
            if (response.data) {
                setPopup(response.data.popUpQuizzes)
            }
            else {
                toast.warning("Trenutno ne postoje rezultati pretrage u bazi podataka.")
            }
          
            setLoading(false)
        }

        fetch()

    }, [1])



    return (
        <div className="mainPage gamesPage">
            <img src={desktopHeiStar} className="floatingStar" />
            <img src={mobileHeiStar} className="floatingStar-mobile" />
            <img src={grass} className="floating-grass" />
            <div className="limit">
                <div className="info">
                    <div>
                        <div className="title">Igre</div>
                        <div className="text">Pokaži svoje fudbalsko znanje kroz UEFA Champions League kviz pitanja i osvoji poene.</div>
                    </div>
                    {!loading && popup && <Notification popup={popup} />}
                </div>

                <div className="boxBig-holder">
                    <div className="boxBig">
                        <div className="points">Osvoji poene</div>
                        <div className="title">UCL rezultati</div>
                        <div className="text">Priseti se ishoda i statistike UCL utakmica iz sezone 2023/24. Vreme je ograničeno.</div>
                        <Link className="btn"
                            to="/dashboard/games/predictor">
                            Započni igru
                            <img src={chevronRightGrey} />
                        </Link>
                    </div>

                    <div className="boxBig">
                        <div className="points">Osvoji poene</div>
                        <img src={notifyIconWhite} />
                        <div className="title">Mesečni kviz</div>
                        <div className="text">Odgovori na fudbalska pitanja koja smo ti pripremili za ovaj mesec. </div>
                        <Link className="btn"
                            to="/dashboard/games/quiz">
                            Saznaj više
                            <img src={chevronRightGrey} />
                        </Link>
                    </div>

                </div>

            </div>

        </div >
    )
}

export default GamesPage
import React from 'react';
import './mainPage.css';
import chevronRightGrey from '../../images/chevronRightGrey.svg'
import chevronRightWhite from '../../images/chevronRightWhite.svg'
import mobileHeiStar from "../../images/mobileHeiStar.png"
import desktopHeiStar from "../../images/desktopHeiStar.png"
import grass from "../../images/grass.png"
import { Link } from 'react-router-dom'

const MainPage = () => {
  return (
    <div className="mainPage">
      <img src={desktopHeiStar} className="floatingStar" />
      <img src={mobileHeiStar} className="floatingStar-mobile" />
      <img src={grass} className="floating-grass" />
      <div className="limit">
        <div className="title">Dobro došli u Heineken® Klub Šampiona</div>
        <div className="text">Heineken® klub strastvenih navijača svim svojim članovima donosi zabavne igre, ekskluzivan sadržaj i sjajne nagrade. </div>

        <div className="boxBig-holder">
          <div className="boxBig">
            <div className="title">Takmiči se</div>
            <div className="text">Pokaži svoje fudbalsko znanje kroz igru UCL rezultati i kviz pitanja.</div>
            <Link className="btn"
              to="/dashboard/games">
              Igraj
              <img src={chevronRightGrey} />
            </Link>
          </div>

          <div className="boxBig">
            <div className="title">Strastveni navijači</div>
            <div className="text">Ekskluzivni tekstovi i rituali za sve prave navijače.</div>
            <Link className="btn"
              to="/dashboard/fans">
              Saznaj više
              <img src={chevronRightGrey} />
            </Link>
          </div>

          <div className="boxBig">
            <div className="title">Nagrade</div>
            <div className="text">UCL karte - ligaška faza 24/25 (ti biraš meč) i druge nagrade.</div>
            <Link className="btn"
              to="/dashboard/prize-winners/prizes">
              Saznaj više
              <img src={chevronRightGrey} />
            </Link>
          </div>

        </div>
        
        <div className="smallBoxHolder">
          <div>Najpopularnije</div>
          <div>
            <div className="smallBox">
              <div className="points-our">Osvoji poene</div>
              <div className="title">UCL rezultati</div>
              <div className="text">Priseti se ishoda UCL utakmica iz sezone 23/24.</div>
              <Link className="link"
                to="/dashboard/games" >
                <u>Link</u>
                <img src={chevronRightWhite} />
              </Link>
            </div>

            <div className="smallBox">
              <div className="points-our">Osvoji poene</div>
              <div className="title">Mesečni kviz</div>
              <div className="text">Odgovori na fudbalska pitanja koja smo ti pripremili za ovaj mesec.</div>
              <Link className="link"
                to="/dashboard/games/quiz" >
                <u>Link</u>
                <img src={chevronRightWhite} />
              </Link>
            </div>

            <div className="smallBox">
              <div className="points-our">Osvoji poene</div>
              <div className="title">Rituali</div>
              <div className="text">Pokaži nam koji rituali te čine pravim, strastvenim navijačem.</div>
              <Link className="link"
                to="/dashboard/fans/rituals" >
                <u>Link</u>
                <img src={chevronRightWhite} />
              </Link>
            </div>

            <div className="smallBox">
              <div className="points-our">Osvoji poene</div>
              <div className="title">Bonus kviz</div>
              <div className="text">Odgovori na pitanja i možeš da osvojiš neku od nagrada iz posebne kategorije. </div>
              <Link className="link"
                to="/dashboard/games" >
                <u>Link</u>
                <img src={chevronRightWhite} />
              </Link>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default MainPage

import React from 'react'
import './prizeBox.css'
import mobilePrize1 from "../../images/mobilePrize1.png"

const PrizeBox = props => {
  return (
    <div className="prizeBox" isMain={props.isMain}>
            <div>{props.name}</div>
            <div>{props.place}</div>
            <img src={props.isMain==="true"? mobilePrize1 : props.image}/>
    </div>
  )
}

export default PrizeBox
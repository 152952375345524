import React from 'react'
import { Link } from 'react-router-dom'
import './welcomePage.css'
import chevronRightGrey from '../../images/chevronRightGrey.svg'

const WelcomePage = () => {
    return (
        <div className='welcomePage'>
            <div className="welcome-box">
                <div className="text1">Postani deo vrhunske ekipe.</div>
                <div className="text-main">Učlani se u Heineken® Klub Šampiona.</div>
                <Link to='/signup' className='btn'>
                    Registruj se - Uloguj se
                    <img src={chevronRightGrey}/>
                </Link>
            </div>

        </div>
    )
}

export default WelcomePage
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './signup.css'
import InputHolder from '../inputHolder/inputHolder'
import chevronRightGrey from '../../../images/chevronRightGrey.svg'
import { inputFields } from '../inputJSON'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import { SignupSchema } from './signupSchema'
import signupAPI from '../../../api/signupAPI'
import { toast } from 'react-toastify';

const SignupPage = () => {

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SignupSchema)
  })

  const [acceptRules, setAcceptRules] = useState(false)
  const [acceptConsent, setAcceptConsent] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleRules = () => setAcceptRules(!acceptRules)
  const handleConsent = () => setAcceptConsent(!acceptConsent)

  const onSubmit = async (data) => {
    setLoading(true)
    if (acceptRules) {
      data["consentApproved"] = acceptConsent
      data["phone"] = "+381" + data.phone
      data["code"] = data.code.toUpperCase()
      let response = await signupAPI(data)
      if (response?.data?.success) {
        toast.success(response.data.success.message)
      }
      else if (response?.response?.data) {
       
        toast.error(response.response.data.error.message)
      }
      else {
        toast.error("Došlo je do greške, pokušajte ponovo")
      }
      
    }

    else {
      toast.error(`„Prihvatam pravila korišćenja je obavezno“`)
    }
    setLoading(false)
  }

  return (
    <div className="signupPage">
      <div className="title">Registruj se</div>
      <div className="text">Za uspešnu registraciju popuni polja važećim podacima i uživaj u sadržaju Heineken® Kluba Šampiona.</div>

      {inputFields.map(input => (
        <InputHolder key={input.name} fields={input} err={errors} register={register} />
      ))}

      <div className="holder">
        <div className="radioHolder">
          <input type="checkbox" name="acceptRules" checked={acceptRules} onChange={handleRules} />
          <label>„Prihvatam <Link to="/dashboard/rules">pravila korišćenja“</Link>  </label>
        </div>

        <div className="radioHolder">
          <input type="checkbox" name="acceptConsent" checked={acceptConsent} onChange={handleConsent} />
          <label>Slažem se da HEINEKEN® Srbija obrađuje moje lične podatke za svrhu oglašavanja. Više informacija
            <a href="https://heinekensrbija.rs/legal.1121.html" target="_blank"> ovde.</a>
          </label>
        </div>
      </div>

      {!loading &&
        <div className="btn"
          onClick={!loading ? handleSubmit(onSubmit) : () => { }}>
          {loading ? "Loading..." : "Registruj se"}
          <img src={chevronRightGrey} />
        </div>}


    </div>
  )
}

export default SignupPage
import axios from "axios";
import relogUser from "./relogUser";
import { toast } from 'react-toastify';

const baseUrl = process.env.REACT_APP_API_URL;


const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

const refreshAxiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use((config) => {
    const accessToken = JSON.parse(window.localStorage.getItem("user"));
    const refreshToken = JSON.parse(window.localStorage.getItem("refreshToken"))
    

    if (accessToken) {
        if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`
    }
    if (refreshToken) {
        if (config.headers) config.headers["Authorization-refresh"] = `Refresh ${refreshToken}`
    }
    return config;
})

refreshAxiosInstance.interceptors.request.use((config) => {
    const refreshToken = JSON.parse(window.localStorage.getItem("refreshToken"))
    if (refreshToken) {
        if (config.headers) config.headers["Authorization-refresh"] = `Refresh ${refreshToken}`
    }
    return config;
})

refreshAxiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
           
            // Handle token refresh error
            toast.error("Sesija je istekla molimo ulogujte se ponovo")
            window.localStorage.setItem("user", null)
            window.localStorage.setItem("refreshToken", null)
            window.location.replace("/")
        }

        // Return a Promise rejection if the status code is not 401
        return Promise.reject(error);
    }
)

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const refreshToken = JSON.parse(window.localStorage.getItem("refreshToken"))

        if (error.response && error.response.status === 401) {
       
            try {
                // Refresh the access token
                const relogResponse = await relogUser();
             

                if(relogResponse?.data.success){
                    let newAccessToken = relogResponse.data.success.data.token.token
                    // Set new access token in local storage
                    window.localStorage.setItem("user", JSON.stringify(newAccessToken))
                    // Update the request headers with the new access token
                    error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

                    const originalRequest = error.config
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`

                    return axios(originalRequest)
                }
                

            } catch (refreshError) {
                
            }
        }
        // Return a Promise rejection if the status code is not 401
        return Promise.reject(error);
    }
)

export {
    axiosInstance, refreshAxiosInstance
};
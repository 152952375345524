import { axiosInstance } from "./api_instance";


export default async function fetchPostByID(id) {

    try {
        const response = await axiosInstance.get(`/posts/${id}`)
        if(response.status === 200){
            return response.data.success
        }

    } catch (error) {
        let msg = await error.response.data.error.message
        
        return msg
    }
} 
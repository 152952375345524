import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import ItemTemplate from '../../itemTemplate/itemTemplate'
import fetchPostByID from '../../../../api/fetchPostByID'
import { toast } from 'react-toastify';

const ItemFootballCo = () => {

    const [postInfo, setPostInfo] = useState({})
    const [otherPosts, setOtherPosts] = useState([])
    let { id } = useParams()

    useEffect(() => {
        const fetch = async () => {
            let response = await fetchPostByID(id)
           
            if (response.data) {
                setPostInfo(response.data.post)
                setOtherPosts(response.data.posts)
            }
            else {
                toast.error(response)
            }
          
        }

        fetch()

    }, [id])

    return (
        <div className="podcast-item">
            <ItemTemplate title={postInfo.title}
                text={postInfo.subTitle}
                contentImage={postInfo.contentImage}
                description={postInfo.description}
                contentGallery={postInfo.contentGallery}
                contentVideo={postInfo.contentVideo}
                description2={postInfo.description2}
                path="footballco"
                otherPosts={otherPosts}
                articleTitle="Izdvajamo"
                articleText="Još ekskluzivnog sadržaja koji će te zanimati." />
        </div>
    )
}

export default ItemFootballCo
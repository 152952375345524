import React, { useState } from 'react';
import './predictorAccordion.css';
import plusCircle from "../../../../images/plus-circle.svg";
import minusCircle from "../../../../images/minus-circle.svg";

function AccordionItem({ title, content }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`accordion-item ${isOpen ? 'active' : ''}`}>
            <div className="accordion-header">
                <div>{title}</div>
                {isOpen && <div onClick={toggleAccordion}><img src={minusCircle} /> </div>}
                {!isOpen && <div onClick={toggleAccordion}><img src={plusCircle} /> </div>}
            </div>
            {isOpen && <div className="accordion-content">{content}</div>}
        </div>
    );
}

function finalScore(finalScore, setMatchData) {
    const handleChange = (value, index) => {

        let temp = finalScore == null ? [null, null] : finalScore
        temp[index] = value
       


        setMatchData(prevMatchData => {
            return {
                ...prevMatchData,
                finalScore: temp
            }
        })
    }
    return (
        <div className="box-wrapper">
            <input className="predict-box goals" type="number" max={99} min={0} value={finalScore === null? null : finalScore[0]} onChange={(e) => handleChange(e.target.value, 0)} />
            <input className="predict-box goals" type="number" max={99} min={0} value={finalScore === null? null : finalScore[1]} onChange={(e) => handleChange(e.target.value, 1)} />
        </div>
    );
}

function ballPossesionFunction(match, setMatchData) {
    const handleChange = (value) => {
        setMatchData(prevMatchData => {
            return {
                ...prevMatchData,
                ballPossesion: value
            }
        })
    }
    return (
        <div className="box-wrapper more-corners-wrapper">
            <div className={"box more-corners-box" + (match.ballPossesion == 1 ? "  active-prediction" : "")} onClick={(e) => handleChange(1)}>{match.homeTeam.name}</div>
            <div className={"box more-corners-box" + (match.ballPossesion == 2 ? "  active-prediction" : "")} onClick={(e) => handleChange(2)}>{match.awayTeam.name}</div>
            <div className={"box more-corners-box" + (match.ballPossesion == 3 ? "  active-prediction" : "")} onClick={(e) => handleChange(3)}>Izjednačeno</div>
        </div>
    );
}

function totalFauls(totalFouls, setMatchData) {
    const handleChange = (value) => {
        setMatchData(prevMatchData => {
            return {
                ...prevMatchData,
                totalFouls: value
            }
        })
    }
    return (
        <div className="box-wrapper">
            <div className={"box more-fauls-box " + (totalFouls == 1 ? "  active-prediction" : "")} onClick={(e) => handleChange(1)}>0-14</div>
            <div className={"box more-fauls-box" + (totalFouls == 2 ? "  active-prediction" : "")} onClick={(e) => handleChange(2)}>15-21</div>
            <div className={"box more-fauls-box" + (totalFouls == 3 ? "  active-prediction" : "")} onClick={(e) => handleChange(3)}>22-28</div>
            <div className={"box more-fauls-box" + (totalFouls == 4 ? "  active-prediction" : "")} onClick={(e) => handleChange(4)}>29+</div>
        </div>
    );
}

function totalCorners(totalCorners, setMatchData) {
    const handleChange = (value) => {
        setMatchData(prevMatchData => {
            return {
                ...prevMatchData,
                totalCorners: value
            }
        })
    }
    return (
        <div className="box-wrapper">
            <input className="predict-box goals" type="number" max={99} min={0} value={totalCorners} onChange={(e) => handleChange(e.target.value)} />
        </div>
    );
}

function moreShotsIn(match, setMatchData) {
    const handleChange = (value) => {
        setMatchData(prevMatchData => {
            return {
                ...prevMatchData,
                moreShoots: value
            }
        })
    }
    return (
        <div className="box-wrapper more-corners-wrapper">
            <div className={"box more-corners-box" + (match.moreShoots == 1 ? "  active-prediction" : "")} onClick={(e) => handleChange(1)}>{match.homeTeam.name}</div>
            <div className={"box more-corners-box" + (match.moreShoots == 2 ? "  active-prediction" : "")} onClick={(e) => handleChange(2)}>{match.awayTeam.name}</div>
            <div className={"box more-corners-box" + (match.moreShoots == 3 ? "  active-prediction" : "")} onClick={(e) => handleChange(3)}>Izjednačeno</div>
        </div>
    );
}

function Accordion({match, setMatchData}) {
    return (
        <div className="accordion">
            <AccordionItem title="Rezultat utakmice" content={finalScore(match.finalScore, setMatchData)} />
            <AccordionItem title="Veći posed lopte na utakmici (%)" content={ballPossesionFunction(match, setMatchData)} />
            <AccordionItem title="Ukupno kornera na utakmici" content={totalCorners(match.totalCorners, setMatchData)} />
            <AccordionItem title="Ukupno faulova na utakmici" content={totalFauls(match.totalFouls, setMatchData)} />
            <AccordionItem title="Više šuteva u okvir gola" content={moreShotsIn(match, setMatchData)} />
        </div>
    );
}

export default Accordion;

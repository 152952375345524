import { axiosInstance } from "./api_instance";


export default async function startQuiz(ID) {

    try {
        const response = await axiosInstance.post(`/quiz/${ID}/start`, {})
        if(response.status === 200){
            return response.data.success
        }

    } catch (error) {
        let msg = await error.response.data.error.message
      
        return msg
    }
} 
import { axiosInstance } from "./api_instance";

export default async function forgotPasswordAPI(mail) {
    try {
        const response = await axiosInstance.post("/auth/forgot-password", {"email": mail})
      
        return response.data.success.message
    } catch (error) {
        return error.response.data.error.message
    }
} 
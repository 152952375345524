import prize1 from "../../images/prize1.png"
import prize2 from "../../images/prize2.png"
import prize3 from "../../images/prize3.png"
import prize4 from "../../images/prize4.png"
import prize5 from "../../images/prize5.png"
import prize6 from "../../images/prize6.png"
import prize7 from "../../images/prize7.png"
import prize8 from "../../images/prize8.png"
import prize9 from "../../images/prize9.png"
import prize10 from "../../images/prize10.png"
import prize11 from "../../images/prize11.png"
import prizeP1 from "../../images/prizeP1.png"
import prizeP2 from "../../images/prizeP2.png"

const prizeArray1 = [{ name: "2 karte i put za UCL utakmicu po tvom izboru za ligašku fazu sezone 2024/25.", place: "1.mesto", image: prize1 }]

const prizeArray2 = [{ name: "FUDBALSKA LOPTA", place: "2.mesto - 5.mesto", image: prize2},
{ name: "UCL JAKNA", place: "6.mesto - 10.mesto", image: prize3 },
{ name: "RANAC", place: "11.mesto - 15.mesto", image: prize4 },
{ name: "VAUČER ZA TERMIN U BALONU", place: "16.mesto - 20.mesto", image: prize5 },
{ name: "VAUČER ZA „STEJŠN KLUB“", place: "21.mesto - 25.mesto", image: prize6 },
{ name: "POLO MAJICA", place: "26.mesto - 30.mesto", image: prize7 },
{ name: "MAJICA", place: "31.mesto - 35.mesto", image: prize8 },
{ name: "FLAŠICA", place: "36.mesto - 55.mesto", image: prize9 },
{ name: "KAPA", place: "56.mesto - 65.mesto", image: prize10 },
{ name: "HEINEKEN PIVO – PAKET 4x0.5L", place: "66.mesto - 100.mesto", image: prize11 }]

const prizeArray3 = [{ name: "FUDBALSKA LOPTA", place: "1.mesto", image: prizeP1 },
{ name: "FLAŠICA", place: "2.mesto - 10.mesto", image: prizeP2 }]





export { prizeArray1, prizeArray2, prizeArray3 }
import { refreshAxiosInstance } from "./api_instance";

export default async function relogUser() {
    try {
        const response = await refreshAxiosInstance.get("/auth/refresh-token")
        
        return response
    } catch (error) {
        
        return error
    }
} 
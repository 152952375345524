import React, { useEffect, useState } from 'react'
import {Link} from "react-router-dom"
import "./games.css"
import "./notification.css"
import notifyIcon from "../../images/notifyIcon.png"
import clockIcon from "../../images/clockIcon.png"
import moment from 'moment-timezone';

const Notification = props => {

    const [currentTime, setCurrentTime] = useState(moment().tz("Europe/Belgrade"))
    const timeBetween = moment.duration(moment.tz(props.popup.startDate, "Europe/Belgrade").diff(currentTime))

    
    
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment().tz("Europe/Belgrade"));
        }, 1000);

        return () => clearInterval(interval);
    }, [])

    

    return (
        <>
            {timeBetween > 0 && timeBetween.days() === 0 && timeBetween.hours() < 12 && 
                <div className="notification quizWaiting">
                    <div>
                        <img src={clockIcon} />
                        <div className="time">{timeBetween.hours()}h:{timeBetween.minutes()}min:{timeBetween.seconds()}s</div>
                    </div>
                    <div className="text">
                    Novi kviz počinje uskoro.
                    </div>
                </div>
            }

            {timeBetween < 0 &&
                <div className="notification quizReady">
                    <div className="points">
                        {props.popup.points} poena
                        <img src={notifyIcon} />
                    </div>

                    <div className="title">{props.popup.userCompletedQuiz? "Bonus kviz je odigran" : "Bonus kviz je dostupan"}</div>
                    <div className="text">Igraj za posebne nagrade i dodatne poene.</div>
                    {!props.popup.userCompletedQuiz && <Link className="link" to={`/dashboard/games/quiz/popup/${props.popup["_id"]}`}>ZAPOČNI KVIZ</Link>}
                </div>
            }
        </>

    )
}

export default Notification

import React from 'react';
import "./rulesPage.css";
import pdfFile from "../../assets/heinekenRulesFinal.pdf";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const pageArray = [1, 2, 3, 4, 5, 6, 7, 8]

function Rules() {
    return (
        <div className="rulesPage">
            <Document file={pdfFile} className="LargePDF" loading={<p className="loadingPDF">Sačekajte da se učita PDF dokument...</p>}>
                {pageArray.map(arrIndex=>(
                    <Page pageNumber={arrIndex}
                          scale={1.8}/>
                ))}          
            </Document>

            <Document file={pdfFile} className="MediumPDF" loading={<p className="loadingPDF">Sačekajte da se učita PDF dokument...</p>}>
                {pageArray.map(arrIndex=>(
                    <Page pageNumber={arrIndex}
                          scale={1.3}/>
                ))}          
            </Document>

            <Document file={pdfFile} className="SmallPDF" loading={<p className="loadingPDF">Sačekajte da se učita PDF dokument...</p>}>
                {pageArray.map(arrIndex=>(
                    <Page pageNumber={arrIndex}
                          scale={1}/>
                ))}          
            </Document>

            <Document file={pdfFile} className="ExtraSmallPDF" loading={<p className="loadingPDF">Sačekajte da se učita PDF dokument...</p>}>
                {pageArray.map(arrIndex=>(
                    <Page pageNumber={arrIndex}
                          scale={0.5}/>
                ))}          
            </Document>
        </div>
    )
}

export default Rules
export const dropdownArray = [{
    id: 0, title: "IGRE", path: "/dashboard/games", elements: [{ title: "UCL rezultati", path: "/dashboard/games/predictor" },
    { title: "Mesečni kviz", path: "/dashboard/games/quiz" }]
},
{
    id: 1, title: "NAVIJAČI", path: "/dashboard/fans", elements: [{ title: "Rituali", path: "/dashboard/fans/rituals" },
    { title: "Ekskluzivan sadržaj", path: "/dashboard/fans/footballco" },
    { title: "Priče navijača", path: "/dashboard/fans/influencers" },
    ]
},
{
    id: 2, title: "NAGRADE I DOBITNICI", path: "/dashboard/prize-winners", elements: [{ title: "Nagrade", path: "/dashboard/prize-winners/prizes" },
    { title: "Dobitnici", path: "/dashboard/prize-winners/winners" },]
}]
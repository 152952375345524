import './progressBar.css';

const defaultStyle = {
    barFill: "white",
    progressFill: "#49A942",
}
const votedStyle = {
    barFill: "#EDEDF3",
    progressFill: "#D10003",
}
const ProgressBar = ({ progress, voted=false }) => {
    const style = voted ? votedStyle : defaultStyle;
    return (
        <div className="progressBarContainer progressText">
            <div className={voted ? "votedText": ""}>{progress}%</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="8" viewBox="0 0 100% 8" fill="none">
                <rect x="0.5" width="100%" height="8" rx="4" fill={style.barFill} fill-opacity="0.2" />
                <rect x="0.5" width={progress + '%'} height="8" rx="4" fill={style.progressFill} />
            </svg>
        </div>
    )
}

export default ProgressBar;
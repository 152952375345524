import React from 'react'
import MainTemplate from '../mainTemplate/mainTemplate'

const Influencers = () => {
  return (
    <MainTemplate title="Priče navijača"
                  text="Pročitaj navijačke priče fudbalskih fanova iz čitavog sveta, među kojima su i domaće poznate ličnosti. Saznaj šta ih to čini pravim navijačima."
                  postType="influencers"
                  path="influencers"/>
  )
}

export default Influencers